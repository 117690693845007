import { SearchResultMatch } from "~/api/query_fns/search";
import MatchHighlight, {
  sanitizeSearchHighlights,
  stripSearchHighlights,
} from "~/components/MatchHighlight";

import { Button } from "~/components/ui/button";
import { Paragraph } from "~/components/ui/paragraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import ColorScaleNumber from "~/components/ColorScaleNumber";

interface SearchResultProps {
  id: string | null;
  filename: string;
  matches: SearchResultMatch[];
  uploaderEmail: string;
  organizationName: string;
  setActiveSearchResultDoc: (
    doc: {
      id: string;
      page: number;
      highlight?: string;
      matchType?: string;
    } | null
  ) => void;
}

const Match = ({
  match,
  setActiveSearchResultDoc,
  documentId,
}: {
  match: SearchResultMatch;
  setActiveSearchResultDoc: SearchResultProps["setActiveSearchResultDoc"];
  documentId: string | null;
}) => {
  return (
    <div className="mb-4">
      <div className="flex flex-row items-baseline gap-1 pb-3 text-sm font-semibold">
        {match.type === "semantic" ? (
          <FontAwesomeIcon
            icon={faHighlighter}
            className="text-sm text-gray-500"
          />
        ) : (
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="text-sm text-gray-500"
          />
        )}
        <Paragraph variant="medium">
          {match.type === "lexical" ? "Text Search:" : "Highlight:"}
        </Paragraph>
        <ColorScaleNumber>
          {match.score.toString().slice(2, 4)}%
        </ColorScaleNumber>
      </div>
      <MatchHighlight variant={match.type}>
        <Button
          variant="link"
          className="h-auto p-0 pb-3"
          onClick={() =>
            documentId &&
            setActiveSearchResultDoc({
              id: documentId,
              page: match.pageNumber,
              highlight: stripSearchHighlights(match.highlight),
              matchType: match.type,
            })
          }
        >
          pg. {match.pageNumber}
        </Button>

        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeSearchHighlights(match.highlight),
          }}
        />
      </MatchHighlight>
    </div>
  );
};

export default Match;
