import { useEffect } from "react";
import useSpeechToText, { ResultType } from "react-hook-speech-to-text";

/**
 * v0 by Vercel.
 * @see https://v0.dev/t/EoycMjUngzn
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

interface MicProps {
  onMicButtonPress: (placeholder: string, prompt: string) => void;
  onSpeechResult: (result: string) => void;
  listening: boolean;
  setListening: (listening: boolean) => void;
}

export default function Mic({
  onMicButtonPress,
  onSpeechResult,
  listening,
  setListening,
}: MicProps) {
  const { startSpeechToText, stopSpeechToText, results } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  useEffect(() => {
    if (results.length > 0) {
      console.log("Speech results updated:", results);
      const latestResult =
        typeof results[results.length - 1] === "string"
          ? results[results.length - 1]
          : (results[results.length - 1] as ResultType).transcript;
      if (typeof latestResult === "string") {
        onSpeechResult(latestResult);
      } else {
        onSpeechResult(latestResult.transcript);
      }
      onMicButtonPress("Ask a question...", "New Prompt");
    }
  }, [results, onSpeechResult, onMicButtonPress]);

  return (
    <div
      className={`outline-darkgrey relative mr-2 flex h-10 w-10 cursor-pointer items-center  justify-center text-gray-500 transition-transform duration-300 ease-in-out  hover:scale-105 ${
        listening ? "outline outline-red-500" : ""
      }`}
      onClick={() => {
        console.log("Mic button clicked. Listening state:", listening);
        if (listening) {
          console.log("Stopping speech to text");
          stopSpeechToText();
          setListening(false);
          onMicButtonPress("Ask a question...", "New Prompt");
        } else {
          console.log("Starting speech to text");
          startSpeechToText();
          setListening(true);
          onMicButtonPress("Start Speaking ...", "New Prompt");
        }
      }}
    >
      <MicIcon className={`h-6 w-6`} />
    </div>
  );
}

function MicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
      <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
      <line x1="12" x2="12" y1="19" y2="22" />
    </svg>
  );
}
