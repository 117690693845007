import { Navigate, Outlet } from "react-router-dom";
import useSession from "~/hooks/useSession";
import { ResponseData as SessionData } from "~/api/query_fns/session";

interface ProtectedRouteProps {
  roles: NonNullable<SessionData["session"]["role"]>[];
}

const ProtectedRoutes = ({ roles }: ProtectedRouteProps) => {
  const { session } = useSession();
  const isAuthorized = session?.role && roles.includes(session.role);
  return isAuthorized ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
