import { useLoaderData, useLocation, useNavigate } from "react-router-dom";

import { useState } from "react";
import { getSearch } from "~/api";
import Pagination from "~/components/Pagination";
import { Card, CardContent } from "~/components/ui/card";
import { Label } from "~/components/ui/label";
import useSession from "~/hooks/useSession";
import SearchResult from "./Search/SearchResult";

import { Heading } from "~/components/ui/heading";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Input } from "~/components/ui/input";
import SearchTooltip from "~/components/SearchTooltip";

type SearchLoaderResponse = Awaited<ReturnType<typeof loader>>;

const perPage = 10;

export const loader = async ({ request }: { request: Request }) => {
  const url = new URL(request.url);
  const query = url.searchParams.get("query");
  const page = url.searchParams.get("page");

  return await getSearch({
    lexical: query || "",
    page: parseInt(page || "1"),
    per_page: perPage,
    qumis_reference_docs: url.searchParams.get("qumisRefDocs") === "1",
    org_reference_docs: url.searchParams.get("orgRefDocs") === "1",
    org_policies: url.searchParams.get("orgPolicies") === "1",
  });
};

const Search = () => {
  const { results, total } = useLoaderData() as SearchLoaderResponse;
  const { session } = useSession();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page") || "1");
  const query = params.get("query");

  const [currentQuery, setCurrentQuery] = useState<string>(query || "");
  const qumisRefDocs = params.get("qumisRefDocs") === "1";
  const orgRefDocs = params.get("orgRefDocs") === "1";
  const orgPolicies = params.get("orgPolicies") === "1";

  const handleNavigate = ({
    query,
    page,
    qumisRefDocs,
    orgRefDocs,
    orgPolicies,
  }: {
    query?: string;
    page?: number;
    qumisRefDocs?: boolean;
    orgRefDocs?: boolean;
    orgPolicies?: boolean;
  }) => {
    query && params.set("query", query);
    page && params.set("page", page.toString());
    qumisRefDocs !== undefined &&
      params.set("qumisRefDocs", qumisRefDocs ? "1" : "0");
    orgRefDocs !== undefined &&
      params.set("orgRefDocs", orgRefDocs ? "1" : "0");
    orgPolicies !== undefined &&
      params.set("orgPolicies", orgPolicies ? "1" : "0");

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  console.error(orgRefDocs);

  return (
    <div className=" mx-auto max-h-[80vh] w-2/3 overflow-y-auto p-6">
      <section className="mt-8">
        <Heading
          variant="h1"
          className="custom-shadcn-components mt-2 text-black"
        >
          Search Results
        </Heading>
      </section>

      <div className="custom-shadcn-components col-span-3 mt-4 pb-32">
        <Card className="mb-2 mt-4 ">
          <CardContent className="p-0">
            <div className="relative">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNavigate({ query: currentQuery, page: 1 });
                }}
              >
                <Input
                  className="rounded-b-none border-none p-3 pr-11"
                  placeholder="Search..."
                  value={currentQuery}
                  onChange={(e) => setCurrentQuery(e.target.value)}
                />
                <MagnifyingGlassIcon className="absolute right-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500" />
              </form>
            </div>
            <div className="flex flex-row items-center justify-between border-t border-border p-3">
              <div className="flex flex-row items-center justify-start gap-2">
                <Label className="text-muted-foreground">Include:</Label>
                <div className="flex flex-row gap-1">
                  {/* <input
                    type="checkbox"
                    name="includes"
                    id="includes-user-org-ref-docs"
                    checked={orgRefDocs}
                    onChange={(e) =>
                      handleNavigate({ orgRefDocs: e.target.checked })
                    }
                  />
                  <Label
                    className="font-normal text-muted-foreground"
                    htmlFor="includes-user-org-ref-docs"
                  >
                    Uploaded Documents
                  </Label> */}
                </div>
                {session?.organizationName !== "Qumis" && (
                  <div className="flex flex-row gap-1">
                    <input
                      type="checkbox"
                      name="includes"
                      id="includes-qumis-ref-docs"
                      checked={qumisRefDocs}
                      onChange={(e) =>
                        handleNavigate({ qumisRefDocs: e.target.checked })
                      }
                    />
                    <Label
                      className="font-normal text-muted-foreground"
                      htmlFor="includes-qumis-ref-docs"
                    >
                      Qumis Database
                    </Label>
                  </div>
                )}
                <div className="flex flex-row gap-1">
                  <input
                    type="checkbox"
                    name="includes"
                    id="includes-user-org-policies"
                    checked={orgPolicies}
                    onChange={(e) =>
                      handleNavigate({ orgPolicies: e.target.checked })
                    }
                  />
                  <Label
                    className="font-normal text-muted-foreground"
                    htmlFor="includes-user-org-policies"
                  >
                    Uploaded Policies
                  </Label>
                </div>
              </div>
              <SearchTooltip />
            </div>
          </CardContent>
        </Card>

        <div className="my-5 text-sm text-gray-500">
          {total === 1 ? "1 result" : `${total} results`}
        </div>
        {results.map((result) => (
          <SearchResult key={result.id} result={result} />
        ))}
        {total > perPage && (
          <Pagination
            page={page}
            total={total}
            setPage={(page) => handleNavigate({ page })}
            perPage={perPage}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
