import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
export type ReportSourceType =
  | "email_report"
  | "auth_ui_report"
  | "unauth_ui_report"
  | "auth_ui_policy";
export type openReferenceType = (
  reference: string
) =>
  | { presignedUrl: string; pageNumber: number; isOpen: boolean }
  | { isOpen: boolean };
export type DocViewerState = {
  isOpen: boolean;
  page: number;
  doc: SingleDocResponseData | null;
};

export function useDocViewer() {
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [document, setDocument] = useState<SingleDocResponseData | null>(null);

  const setDocViewerState = ({ isOpen, page, doc }: DocViewerState) => {
    setIsDocViewerOpen(isOpen);
    setInitialPage(page);
    setDocument(doc);
  };

  return {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
  };
}

export function useOpenReferenceDetails(docs: SingleDocResponseData[]) {
  const { isDocViewerOpen, initialPage, document, setDocViewerState } =
    useDocViewer();

  const openReference = (reference: string) => {
    console.log(`Received reference: ${reference}`);

    const referencePattern = /(.+),\s*(pg|page)\s*(\d+)(?:-\d+)?/i;
    const match = reference.match(referencePattern);

    if (match) {
      const filename = match[1].trim();
      const page = parseInt(match[3], 10);
      console.log(`Extracted filename: ${filename}, page: ${page}`);

      const doc = docs.find((doc) => doc.document.filename === filename);
      if (doc) {
        console.log(`Document found: ${filename}`);
        setDocViewerState({
          doc,
          page,
          isOpen: true,
        });
        return {
          presignedUrl: doc.presignedUrl,
          pageNumber: page,
          isOpen: true,
        };
      } else {
        console.log(`Document not found: ${filename}`);
        if (docs.length === 1) {
          const singleDoc = docs[0];
          setDocViewerState({
            doc: singleDoc,
            page: page,
            isOpen: true,
          });
          return {
            presignedUrl: singleDoc.presignedUrl,
            pageNumber: 0,
            isOpen: true,
          };
        }
        return {
          isOpen: false,
        };
      }
    } else {
      console.log(`Reference pattern did not match: ${reference}`);
      if (docs.length === 1) {
        const singleDoc = docs[0];
        setDocViewerState({
          doc: singleDoc,
          page: 0,
          isOpen: true,
        });
        return {
          presignedUrl: singleDoc.presignedUrl,
          pageNumber: 0,
          isOpen: true,
        };
      }
      return {
        isOpen: false,
      };
    }
  };

  return {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
  };
}
