import tw, { TwStyle } from "twin.macro";
import styled from "styled-components";

const paragraphVariants: Record<string, TwStyle> = {
  large: tw`text-base`,
  medium: tw`text-sm`,
  small: tw`text-xs leading-4`,
};

interface ContainerProps {
  variant?: keyof typeof paragraphVariants;
}

const Paragraph = styled.p<ContainerProps>(() => [
  ({ variant = "large" }) => paragraphVariants[variant],
]);

export { Paragraph, paragraphVariants };
