import React from "react";
import { Button } from "~/components/ui/button";

interface GenerateReportButtonProps {
  handleGenerateReport: () => void;
  isButtonDisabled: () => boolean;
}

const StepComponentGenerateReportButton: React.FC<
  GenerateReportButtonProps
> = ({ handleGenerateReport, isButtonDisabled }) => {
  return (
    <Button
      className="mt-10"
      onClick={handleGenerateReport}
      disabled={isButtonDisabled()}
    >
      Generate Report
    </Button>
  );
};

export default StepComponentGenerateReportButton;
