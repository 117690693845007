import { useEffect, useState } from "react";
import axios from "axios";

const Version = () => {
  const [version, setVersion] = useState("");

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get("/package.json");
        setVersion(response.data.version);
      } catch (error) {
        console.error("Failed to fetch version:", error);
      }
    };

    fetchVersion();
  }, []);

  return (
    <div>
      <h2>Current Version: {version}</h2>
    </div>
  );
};

export default Version;
