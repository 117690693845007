import { Navigate } from "react-router-dom";
import { destroyInvite } from "~/api";

export const action = async ({ params }: { params: { id?: string } }) => {
  if (!params.id) throw new Error("No invite ID provided");
  return await destroyInvite({ id: params.id });
};

const DeleteInvite = () => {
  return <Navigate to="/admin/members" />;
};

export default DeleteInvite;
