import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useGenerateReport } from "~/hooks/useGenerateReport";
import ExistingReports from "~/components/ExistingReports";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import UCTemplate from "~/components/UCTemplate";
import UCReportGenerationButtonComponent from "~/components/UCReportGenerationButton";

interface UCReportV2Props {
  reportType: ReportType;
  reportTitle: string;
  newPath: string;
}

const UCPolicyComparisonV2 = ({
  reportType,
  reportTitle,
  newPath,
}: UCReportV2Props) => {
  const [additionalNotes] = useState("");
  const [uploadedDocs] = useState<SingleDocResponseData[]>([]);

  const { isLoading } = useGenerateReport(
    uploadedDocs,
    reportType,
    additionalNotes
  );

  const buttonName = getButtonName(reportType);

  const LeftComponent = () => (
    <ExistingReports
      report_title={reportTitle}
      report_types={[reportType, "tower_analysis"]}
    />
  );

  const RightComponent = () => (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center space-y-4">
        <UCReportGenerationButtonComponent
          route={newPath}
          buttonText={buttonName}
        />
        {/*         <UCReportGenerationButtonComponent
          route={"/tower-analysis-new"}
          buttonText={"Tower Analysis"}
        /> */}
      </div>
    </div>
  );
  return (
    <UCTemplate
      LeftComponent={LeftComponent}
      RightComponent={RightComponent}
      isLoading={isLoading}
      loadingTitle="GENERATING POLICY COMPARISON"
    />
  );
};

function getButtonName(reportType: ReportType): string {
  switch (reportType) {
    case "policy_fact":
      return "Generate Policy Fact Report";
    case "policies_compare":
      return "Generate Policies Comparison Report";
    case "policy_question":
      return "Generate Policy Question Report";
    case "generic_report":
      return "Generate Custom Report";
    default:
      return "Generate Report";
  }
}

export default UCPolicyComparisonV2;
