import { apiFn } from "~/api/utils";

export interface RequestData {
  email: string;
  password: string;
}

export interface ResponseData {
  session: {
    userId: null | string;
    email: null | string;
    role: null | "site_admin" | "owner" | "admin" | "user";
    organizationId: null | string;
    organizationName: null | string;
  };
}

export const getSession = apiFn<void, ResponseData>("GET", "/session");

export const createSession = apiFn<RequestData, ResponseData>(
  "POST",
  "/session"
);

export const destroySession = apiFn<void, ResponseData>("DELETE", "/session");
