import { useRef } from "react";
import { UseQueryResult } from "react-query";
import { SearchResults } from "~/api/query_fns/search";

import SearchResult from "~/routes/GenPrecedentSearch/SearchResult";
import { Label } from "~/components/ui/label";
import Pagination from "~/components/Pagination";

import { Card, CardContent } from "~/components/ui/card";
import SearchTooltip from "~/components/SearchTooltip";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Input } from "~/components/ui/input";
import { Paragraph } from "~/components/ui/paragraph";

interface ToggleProps {
  label: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toggle = ({ label, checked, setChecked }: ToggleProps) => {
  const id = label.toLowerCase().replace(" ", "-");

  return (
    <div className="flex flex-row gap-1">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <Label className="text-xs font-normal text-muted-foreground" htmlFor={id}>
        {label}
      </Label>
    </div>
  );
};

interface SearchResultsColumnProps {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  orgPolicies: boolean;
  setOrgPolicies: React.Dispatch<React.SetStateAction<boolean>>;
  orgReferenceDocs: boolean;
  setOrgReferenceDocs: React.Dispatch<React.SetStateAction<boolean>>;
  qumisReferenceDocs: boolean;
  setQumisReferenceDocs: React.Dispatch<React.SetStateAction<boolean>>;
  searchResults: UseQueryResult<SearchResults, unknown>;
  setActiveSearchResultDoc: React.Dispatch<
    React.SetStateAction<null | {
      id: string;
      page: number;
      highlight?: string;
      matchType?: string;
    }>
  >;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const SearchResultsColumn = ({
  searchResults,
  setActiveSearchResultDoc,
  query,
  setQuery,
  orgPolicies,
  setOrgPolicies,
  orgReferenceDocs,
  setOrgReferenceDocs,
  qumisReferenceDocs,
  setQumisReferenceDocs,
  page,
  setPage,
}: SearchResultsColumnProps) => {
  const topRef = useRef<HTMLDivElement>(null);

  const handlePageChange = (page: number) => {
    if (topRef.current) {
      topRef.current.scrollIntoView();
    }

    setPage(page);
  };

  return (
    <div className="col-span-2 row-span-2 flex flex-col overflow-hidden">
      <Card className="mb-2 ">
        <CardContent className="p-0">
          <div className="relative">
            <form onSubmit={(e) => e.preventDefault()}>
              <Input
                className="rounded-b-none border-none p-3 pr-11"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <MagnifyingGlassIcon className="absolute right-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500" />
            </form>
          </div>
          <div className="flex flex-row items-center justify-between border-t border-border p-3">
            <div className="flex flex-row items-center justify-start gap-2">
              <Label className="self-baseline text-muted-foreground">
                Include:
              </Label>
              <div className="flex flex-row gap-3">
                <Toggle
                  label="Policies"
                  checked={orgPolicies}
                  setChecked={setOrgPolicies}
                />
                <Toggle
                  label="Reference Documents"
                  checked={orgReferenceDocs}
                  setChecked={setOrgReferenceDocs}
                />
                <Toggle
                  label="Qumis Database"
                  checked={qumisReferenceDocs}
                  setChecked={setQumisReferenceDocs}
                />
              </div>
            </div>
            <SearchTooltip />
          </div>
        </CardContent>
      </Card>

      {searchResults.isLoading ? (
        <Paragraph variant="small" className="my-1 text-muted-foreground">
          Loading...
        </Paragraph>
      ) : searchResults.data?.results?.length === 0 ? (
        <Paragraph variant="small" className="my-1 text-muted-foreground">
          No results.
        </Paragraph>
      ) : searchResults.data?.results ? (
        <div className="mb-5 max-h-[72vh] overflow-auto pl-1 pr-1">
          <Paragraph
            variant="small"
            className="my-1 text-muted-foreground"
            ref={topRef}
          >
            {searchResults.data.total} results
          </Paragraph>
          {searchResults.data.results.map((result) => (
            <SearchResult
              key={result.id}
              id={result.id}
              filename={result.filename}
              uploaderEmail={result.uploaderEmail}
              organizationName={result.organizationName}
              matches={result.matches}
              setActiveSearchResultDoc={setActiveSearchResultDoc}
            />
          ))}
        </div>
      ) : searchResults.isError ? (
        <div className="px-1 pt-4 text-sm text-red-500">
          {(searchResults.error as Error)?.message ||
            "An error occured while fetching search results."}
        </div>
      ) : (
        <div className="px-1 pt-2 text-sm text-gray-300">
          Highlight a section of the document to search relevant citations.
        </div>
      )}
      {searchResults.data && (
        <Pagination
          total={searchResults.data.total}
          page={page}
          setPage={handlePageChange}
          perPage={10}
        />
      )}
    </div>
  );
};

export default SearchResultsColumn;
