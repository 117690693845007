import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  const error = useRouteError() as Error;
  console.log(error);
  const message: string = error?.message || "Unknown error";

  return (
    <div className="mx-auto max-w-7xl px-2 py-8">
      <div className="mb-2 text-xl font-semibold">Error</div>
      <div>An error occurred: {message}</div>
    </div>
  );
};

export default ErrorBoundary;
