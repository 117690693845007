import tw, { TwStyle } from "twin.macro";
import styled from "styled-components";

interface ContainerProps {
  scale?: number;
}

const ColorVariants: Record<number | string, TwStyle> = {
  85: tw`text-highlight-scale-85`,
  80: tw`text-highlight-scale-80`,
  70: tw`text-highlight-scale-70`,
  60: tw`text-highlight-scale-60`,
  50: tw`text-highlight-scale-50`,
  49: tw`text-highlight-scale-49`,
};

const scaleToColor = (scale: number) =>
  Math.min(85, Math.max(49, Math.round(scale * 10) * 10));

const ColorScaleNumber = styled.span<ContainerProps>(
  ({ scale = 49 }) => ColorVariants[scaleToColor(scale)]
);

export default ColorScaleNumber;
