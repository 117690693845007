/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";

export default function UCPolicyComparisonNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
  } = useGenerateReportNew("policies_compare");

  if (isLoading) {
    return <LoadingComponent title="GENERATING POLICY COMPARISON" />;
  }

  return (
    <StepReportComponent
      title="Generate Policy Comparison"
      lastStepName="Generate Policy Comparison"
      steps={[
        {
          number: 1,
          label: "Policy Documents",
          description:
            "PDFs of full policies only. Drag and drop files from your computer, not from an external program. Up to 1000 pages of PDF total.",

          component: (
            <PolicyDocumentsComponent
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 2,
          label: "Additional Information",
          description:
            "Please note that this text box is for additional context and will not change the format of the generated report. Ask questions using the Qumis Clerk once the report has generated.",
          component: (
            <AdditionalInformationComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/policy-comparison"
    />
  );
}

const PolicyDocumentsComponent = ({
  handleUploadComplete,
}: {
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step1" className="flex w-full flex-col">
      <UploadArea
        db_document_type="policy"
        onUploadComplete={handleUploadComplete}
      />
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
    />
  );
};
