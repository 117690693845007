import { useEffect, useState, useMemo } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { searchPlugin, SearchPlugin } from "@react-pdf-viewer/search";
import { UseQueryResult } from "react-query";
import { SingleDocResponseData as DocumentResponse } from "~/api/query_fns/documents";

interface SearchResultDocumentProps {
  initialPage: number;
  documentQuery: UseQueryResult<DocumentResponse, unknown>;
  highlightedContent: string;
  matchType: string;
}

export const SearchResultDocument = ({
  initialPage,
  documentQuery,
  highlightedContent,
  matchType,
}: SearchResultDocumentProps) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const handleDocumentLoad = () => setDocumentLoaded(true);

  const pageNavigationPluginInstance = pageNavigationPlugin({
    enableShortcuts: false,
  });

  const { jumpToPage } = pageNavigationPluginInstance;

  const searchPluginInstance: SearchPlugin = searchPlugin({
    onHighlightKeyword: (props) => {
      props.highlightEle.className =
        "absolute mix-blend-multiply last:bg-transparent last:bg-gradient-to-r last:from-[--highlight-color] bg-[--highlight-color]";
    },
  });
  const { highlight, clearHighlights, setTargetPages } = searchPluginInstance;
  const { isLoading, isError, data } = documentQuery;

  useEffect(() => {
    if (isDocumentLoaded) {
      setTimeout(() => {
        if (highlightedContent === "") {
          clearHighlights();
        } else {
          // search doesn't always find a match, so we should
          // at least jump to the right page if we can't highlight anything
          jumpToPage(initialPage - 1);

          setTargetPages((targetPage) =>
            [initialPage, initialPage - 1].includes(targetPage.pageIndex)
          );
          highlight(highlightedContent);
        }
      }, 400);
    }
  }, [isDocumentLoaded, highlightedContent, initialPage]);

  useEffect(() => {
    if (isLoading) {
      setDocumentLoaded(false);
    }
  }, [isLoading]);

  const highlightColorClassName = useMemo(
    () =>
      matchType === "semantic"
        ? "[--highlight-color:theme(colors.highlight.semantic)]"
        : "[--highlight-color:theme(colors.highlight.lexical)]",
    [matchType]
  );

  return (
    <div className="col-span-3 flex flex-col overflow-hidden">
      <div className={"h-full w-full overflow-auto " + highlightColorClassName}>
        {isLoading ? (
          <div className="text-sm text-gray-500">Loading...</div>
        ) : isError ? (
          <div className="text-sm text-gray-500">Error</div>
        ) : (
          data && (
            <Viewer
              fileUrl={data.presignedUrl}
              plugins={[pageNavigationPluginInstance, searchPluginInstance]}
              onDocumentLoad={handleDocumentLoad}
            />
          )
        )}
      </div>
    </div>
  );
};

export default SearchResultDocument;
