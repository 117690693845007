import { Badge } from "~/components/ui/badge";

const ClaimTag = ({
  color,
  number,
}: {
  color: string | null;
  number: string | null;
}) => (
  <Badge
    variant="primary"
    style={color ? { backgroundColor: color } : undefined}
  >
    ID: {number}
  </Badge>
);

export default ClaimTag;
