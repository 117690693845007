import { FC, memo } from "react";
import ReactMarkdown, { Options, Components } from "react-markdown";
import { CodeBlock } from "./CodeBlock";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className
);

type MarkdownProps = {
  content: string;
};

export const Markdown = ({ content }: MarkdownProps) => {
  return (
    <div className="copilotKitMarkdown">
      <MemoizedReactMarkdown
        components={components}
        remarkPlugins={[remarkGfm, remarkMath]}
      >
        {content}
      </MemoizedReactMarkdown>
    </div>
  );
};

const components: Components = {
  p({ children }) {
    return <p>{children}</p>;
  },
  a({ children, ...props }) {
    return (
      <a
        style={{ color: "blue", textDecoration: "underline" }}
        {...props}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  },
  code({ children, className, ...props }) {
    const match = /language-(\w+)/.exec(className || "");

    return (
      <CodeBlock
        key={Math.random()}
        language={(match && match[1]) || ""}
        value={String(children).replace(/\n$/, "")}
        {...props}
      />
    );
  },
};
