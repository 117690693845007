import { apiFn } from "~/api/utils";

export interface ResponseData {
  id: string;
  name: string;
  userCount: number;
  documentCount: number;
  owner: {
    email: string;
  } | null;
  initialInvite: {
    id: string;
    email: string;
    claimedAt: string | null;
  } | null;
  plan?: string;
}

export interface InviteRequestData {
  organization: {
    name: string;
    ownerEmail: string;
  };
}

export interface CreateRequestData {
  organization: {
    name: string;
    ownerEmail: string;
    password: string;
  };
}

export interface ChangePlanRequestData {
  id: string;
  organization: {
    plan: string;
  };
}

export const getOrganizations = apiFn<void, { organizations: ResponseData[] }>(
  "GET",
  "/organizations"
);

export const inviteOrganization = apiFn<InviteRequestData, ResponseData>(
  "POST",
  "/organizations_invite"
);

export const createOrganization = apiFn<CreateRequestData, ResponseData>(
  "POST",
  "/organizations"
);

export const updateOrganization = apiFn<ChangePlanRequestData, ResponseData>(
  "PUT",
  (params) => `/organizations/${params.id}`
);
