import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Button } from "~/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";

import MatchHighlight, {
  sanitizeSearchHighlights,
} from "~/components/MatchHighlight";
import { SearchResult as SearchResultType } from "~/api/query_fns/search";
import { useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Link } from "~/components/ui/link";
import ClaimTag from "~/components/ClaimTag";

const SearchResult = ({ result }: { result: SearchResultType }) => {
  const [matchIndex, setMatchIndex] = useState(0);
  const matchCount = result.matches.length;
  const currentMatch = result.matches[matchIndex];

  const linkTo =
    result.documentType === "policy"
      ? `/chat/${result.id}`
      : `/documents/${result.id}`;

  return (
    <Card className="mb-2">
      <CardHeader>
        {result.claimNumber && (
          <div className="mb-4">
            <ClaimTag color={result.claimColor} number={result.claimNumber} />
          </div>
        )}
        <CardTitle className="hover:underline">
          <RouterLink to={linkTo}>{result.filename}</RouterLink>
        </CardTitle>
        <div className="!m-0 pt-3 text-sm text-gray-500">
          {result.organizationName === "Qumis" ? (
            <span>
              Qumis Database &bull;{" "}
              <span className="capitalize">{result.documentType} document</span>
            </span>
          ) : (
            <span>
              {result.organizationName} &bull;{" "}
              <span className="capitalize">{result.documentType} document</span>
              &bull; Uploaded by {result.uploaderEmail}
            </span>
          )}
        </div>
      </CardHeader>
      <CardContent className="flex items-center">
        <div className="flex-grow">
          <MatchHighlight
            variant="lexical"
            dangerouslySetInnerHTML={{
              __html: sanitizeSearchHighlights(currentMatch.highlight),
            }}
          />
        </div>
        <div className="w-24 flex-none">
          <div className="mb-2 w-full text-center text-gray-500">
            <Link asChild>
              <RouterLink to={`${linkTo}?page=${currentMatch.pageNumber}`}>
                p{currentMatch.pageNumber}
              </RouterLink>
            </Link>
          </div>
          <div className="flex justify-center">
            <Button
              variant="outline"
              className="flex h-8 w-8 p-0"
              onClick={() => setMatchIndex(matchIndex - 1)}
              disabled={matchIndex === 0}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="flex h-8 w-8 p-0"
              onClick={() => setMatchIndex(matchIndex + 1)}
              disabled={matchIndex === matchCount - 1}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SearchResult;
