import { ExclamationTriangleIcon, BellIcon } from "@radix-ui/react-icons";
import { Alert as UIAlert, AlertTitle, AlertDescription } from "./ui/alert";

const Alert = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) => (
  <UIAlert>
    <BellIcon className="h-4 w-4" />
    <AlertTitle>{title || "Alert"}</AlertTitle>
    <AlertDescription>{children}</AlertDescription>
  </UIAlert>
);

export const Destructive = ({ children }: { children: React.ReactNode }) => (
  <UIAlert variant="destructive">
    <ExclamationTriangleIcon className="h-4 w-4" />
    <AlertTitle>Error</AlertTitle>
    <AlertDescription>{children}</AlertDescription>
  </UIAlert>
);

export default Alert;
