import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getDocuments } from "~/api";
import { useQuery } from "react-query";

import DocumentCard from "~/components/DocumentCard";
import Pagination from "~/components/Pagination";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";

interface PolicyListProps {
  linkPrefix: string;
  itemsPerPage: number;
}

const UCPolicyListComponent: React.FC<PolicyListProps> = ({
  linkPrefix,
  itemsPerPage,
}) => {
  const [page, setPage] = useState<number>(1);
  const [query] = useState<string>("");
  const navigate = useNavigate();

  const documentsQuery = useQuery({
    queryKey: ["policyDocuments", page],
    queryFn: () =>
      getDocuments({ page: page, document_type: "policy", uploader: "me" }),
    refetchOnWindowFocus: false,
  });

  return (
    <div className="flex h-full flex-col">
      <Heading
        as="h2"
        className="custom-heading text-black-100 mb-4 flex flex-row gap-2"
      >
        My Policies
      </Heading>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate(`/documents/search?query=${query}&orgPolicies=1`);
        }}
      >
        {/*  <div className="mb-2 mt-4">
         <SearchBox query={query} setQuery={setQuery} />
        </div>  */}
      </form>
      <div className="flex-grow overflow-hidden">
        {documentsQuery.data &&
        documentsQuery.data.documents &&
        documentsQuery.data.documents.length > 0 ? (
          <section className="flex h-full flex-col">
            <Paragraph variant="small" className="my-5 text-muted-foreground">
              Showing {Math.min(documentsQuery.data.total, itemsPerPage)} of{" "}
              {documentsQuery.data.total} Policies
            </Paragraph>
            <div className="flex-grow overflow-y-auto">
              {documentsQuery.data.documents.map((doc) => (
                <Link to={`${linkPrefix}${doc.id}`} key={doc.id}>
                  <DocumentCard doc={doc} />
                </Link>
              ))}
            </div>
            <Pagination
              setPage={(p) => setPage(p)}
              total={documentsQuery.data.total}
              page={page}
              perPage={itemsPerPage}
            />
          </section>
        ) : (
          <Paragraph variant="small" className="my-5 text-muted-foreground">
            No results.
          </Paragraph>
        )}
      </div>
    </div>
  );
};

export default UCPolicyListComponent;
