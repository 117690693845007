import React from "react";
import { Button } from "~/components/ui/button";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cn } from "~/utils";
import { Rating } from "react-simple-star-rating";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";

interface ReportMenuItemProps {
  onRegenerate: () => void;
  onExportPDF: () => void;
  onCopyShareableLink: () => void;
  onAddFiles: () => void;
  rating: number;
  onRating: (rate: number) => void;
}

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "horizontal", decorative = true, ...props },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        "shrink-0 bg-border",
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        className
      )}
      {...props}
    />
  )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export const ReportHorizontalMenu: React.FC<ReportMenuItemProps> = ({
  onRegenerate,
  onExportPDF,
  onCopyShareableLink,
  onAddFiles,
  rating,
  onRating,
}) => {
  const ratingLabels = ["INCORRECT", "BAD", "OK", "GOOD", "GREAT"];

  return (
    <div className="flex items-center justify-end space-x-2 rounded-md p-2">
      <div className="flex flex-col items-start">
        <div className="flex items-center">
          {/* <span className="mr-1 mt-1 text-sm font-medium">
            {rating > 5 ? ratingLabels[rating - 1] : "Report Feedback:"}
          </span> */}
          <Rating
            tooltipDefaultText="CLICK TO RATE"
            onClick={onRating}
            initialValue={rating}
            size={20}
            SVGclassName={`inline-block`}
            allowFraction={false}
            allowTitleTag={true}
            tooltipArray={ratingLabels}
            tooltipStyle={{
              backgroundColor: "#e0e0e0",
              color: "#333",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
            fillColor="black"
            emptyColor="lightgray"
            allowHover={rating === 0}
          />
        </div>
        {/* <div className="mt-1 text-xs text-gray-500">
          {rating > 0
            ? "Thank You For Feedback!"
            : "Rate & Improve This Report."}
        </div> */}
      </div>

      <Separator orientation="vertical" className="h-6" />

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={onRegenerate} variant="ghost" size="sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.3" />
              </svg>
              <span className="sr-only">Re-Generate Report</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Re-Generate Report</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Separator orientation="vertical" className="h-6" />

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={onExportPDF} variant="ghost" size="sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" y1="15" x2="12" y2="3" />
              </svg>
              <span className="sr-only">Export PDF</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Export PDF</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Separator orientation="vertical" className="h-6" />

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={onCopyShareableLink} variant="ghost" size="sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
              </svg>
              <span className="sr-only">Copy Shareable Link</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Copy Shareable Link</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Separator orientation="vertical" className="h-6" />

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={onAddFiles} variant="ghost" size="sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4" />
                <polyline points="14 2 14 8 20 8" />
                <path d="M3 15h6" />
                <path d="M6 12v6" />
              </svg>
              <span className="sr-only">Add Files</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Add Files</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
