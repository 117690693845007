import { Worker } from "@react-pdf-viewer/core";
import { useQuery } from "react-query";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getDocument } from "~/api";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { Heading } from "~/components/ui/heading";
import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { useOpenReferenceDetails } from "../doc-util"; // Add this import
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { CustomChat } from "~/components/util-chat";
import React from "react";
import { defer, Await } from "react-router-dom";
import { LoadingComponent } from "~/utils/util-report";
import ErrorBoundaryReport from "~/components/ErrorBoundaryReport";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { fetchDocumentTexts } from "~/utils";

interface LoaderParams {
  params: {
    id?: string;
  };
}

type genPolicyChatLoaderResponse = SingleDocResponseData; // Update the type for genPolicyChatLoaderResponse

export const loader = async ({ params }: LoaderParams) => {
  const fetchPolicyData = async (): Promise<SingleDocResponseData> => {
    let retryCount = 0;
    const maxRetries = 20;
    const retryDelay = 30000; // 30 seconds

    while (retryCount < maxRetries) {
      const doc = await getDocument({ id: params.id || "", includes: "text" });
      await fetchDocumentTexts([doc]);
      if (doc.document.text) {
        return doc;
      }
      console.log(
        `Text is not available for doc id: ${doc.document.id}, filename: ${doc.document.filename}`
      );
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      retryCount++;
    }
    throw new Error(
      `OCR processing has failed. Try again or contact support@qumis.ai`
    );
  };

  return defer({ policyData: fetchPolicyData() });
};

const getSelection = () => {
  if (window.getSelection) {
    return window.getSelection() || null;
  }

  if (document.getSelection) {
    return document.getSelection() || null;
  }
  return null;
};

const GenPolicyChat = () => {
  const { policyData } = useLoaderData() as {
    policyData: Promise<genPolicyChatLoaderResponse>;
  };

  return (
    <React.Suspense
      fallback={
        <LoadingComponent title="Loading Policy" showProgressBar={true} />
      }
    >
      <Await resolve={policyData} errorElement={<ErrorBoundaryReport />}>
        {(loadedData: genPolicyChatLoaderResponse) => (
          <PolicyChatContent data={loadedData} />
        )}
      </Await>
    </React.Suspense>
  );
};

const PolicyChatContent = ({ data }: { data: genPolicyChatLoaderResponse }) => {
  const { presignedUrl, document: currentDocument } = data;

  const [searchParams] = useSearchParams();
  const [highlighted, setHighlighted] = useState<null | string>(null);

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
  } = useOpenReferenceDetails([data]); // Use the hook

  const [activeSearchResultDoc] = useState<{
    id: string;
    page: number;
    highlight?: string;
    matchType?: string;
  } | null>(null);
  const viewingActiveSearchResultDoc = !!activeSearchResultDoc;

  const searchResultDocQuery = useQuery({
    queryKey: ["document", activeSearchResultDoc?.id],
    queryFn: () => getDocument({ id: activeSearchResultDoc?.id as string }),
    enabled: !!activeSearchResultDoc,
    refetchOnWindowFocus: false,
  });

  const setHighlightedContent = () => {
    const highlightEvent = getSelection();
    const highlightedContent =
      highlightEvent?.toString()?.replace(/(\r\n|\n|\r)/gm, "") || null;
    setHighlighted(highlightedContent);
  };

  const isSearchResultDoc = !!searchResultDocQuery?.data?.document;

  let context =
    currentDocument && currentDocument.text ? currentDocument.text : "";
  context = `
You are a highly advanced AI assistant powered by Google Gemini, specializing in providing accurate and comprehensive analysis of insurance policy documents for expert users. Your goal is to answer user questions with the highest degree of precision, leveraging Gemini's advanced reasoning capabilities and a structured analytical approach.

**Instructions:**

1. **Input:** You will receive insurance policy documents (text or PDF format) and a user's question. The question may involve a single policy or comparisons across multiple policies.

2. **Gemini-Enhanced Structured Analysis (Triggered by User Question):**
    * Upon receiving a question, activate the following Gemini-specific techniques:
        * **Tool Use:** If available, use relevant tools (e.g., PDF parsers, definition lookups) to extract and structure key information from the policies.
        * **Multi-Modal Understanding:**  If the policies contain diagrams, tables, or other non-textual elements, leverage Gemini's multi-modal capabilities to incorporate these into your analysis.
        * **Code Interpretation (if applicable):** If the policies involve structured data or calculations (e.g., in premium schedules), utilize Gemini's code interpretation abilities to ensure accurate analysis.
    * Perform a targeted analysis of the relevant policy sections using the "Checklist for Analyzing Insurance Policies" (provided below).
    * Focus your analysis on the elements most pertinent to the user's question. 
    * Identify and extract all relevant key concepts, definitions, and provisions.

3. **Gemini-Enhanced Response Generation:**
    * Provide a precise and comprehensive answer to the user's question.
    * Explicitly cite the supporting evidence from the policy documents using the format: [document_name.pdf, pg X].
    * **Transparency is paramount:** If interpretation or reasoning is required, provide a clear and concise chain-of-thought, outlining the steps you took to arrive at your conclusion.
    * **Enhanced Reasoning and Explanation:** Use Gemini's advanced reasoning capabilities to provide clear and concise chain-of-thought explanations whenever interpretation or complex reasoning is required, outlining the steps and evidence leading to your conclusion.
    * **Structured Comparisons:** If comparisons across multiple policies are needed, leverage Gemini's table generation capabilities to present a structured comparison highlighting relevant differences and similarities with precise citations.

4. **Context Window Management (for Large Inputs):**
    * If the combined input approaches the context window limit:
        * Prioritize sections most relevant to the question.
        * If necessary, concisely summarize less relevant sections without omitting crucial details.
        * Clearly indicate any summarization to maintain transparency.


**Checklist for Analyzing Insurance Policies:** 

## I. Initial Overview & Identification

**Elements:**

* **Type of Insurance Policy:**  (e.g., Commercial General Liability, Homeowners, Professional Liability, Errors & Omissions, Fidelity Bond, etc.)
* **Parties to the Contract:**  (Named Insured(s), Insurer, Additional Insureds) 
* **Policy Period:** (Effective Date, Expiration Date)
* **Policy Limits:** (Per Occurrence, Aggregate, etc. - Note for each coverage type) 

## II. Declarations Page ("Dickered Deal")

**Elements:**
* **Named Insured(s):** (Verify name and address accuracy)
* **Policy Number:** (Match to all documents)
* **Location of Insured Premises:** (Confirm address)
* **Covered Property:** (Detailed description - if applicable)
* **Coverage Types:** (List selections)
* **Premium:** (Verify payment and amount) 

## III. Insuring Agreement(s) ("The Front Door")

**Elements:**
* **Scope of Coverage:** (What risks/perils are insured against?)
* **Triggering Event:** (What specific event activates coverage?) 
* **Duties of the Insurer:** (Duty to defend, duty to indemnify, etc.)

## IV. Definitions (What the Policy Really Means)

**Guidelines:**
* **Key Terms:** Identify and review ALL defined terms. Definitions control interpretation.
* **Common Terms - Specific Definitions:**  Pay close attention.
* **Undefined Terms:** Assess plain and ordinary meaning (dictionaries, case law).

## V. Exclusions (What the Policy Doesn't Cover)

**Analysis Points:**
* **Scope of Exclusions:** Specific circumstances precluding coverage.
* **Causal Link:** Does the exclusion require a link between the excluded event and the loss?
* **Ambiguity:** Resolve in favor of the insured.
* **Public Policy:** Does the exclusion violate public policy?
* **Exceptions to Exclusions:** Identify anything that might reinstate coverage.

## VI. Conditions ("The Fine Print")

**Elements:**
* **Notice Provisions:** Timeframes and methods for claim notice. 
* **Cooperation Clause:** Insured's duty to cooperate with investigation.
* **Other Conditions Precedent:**  Identify any that must be met for coverage.

## VII. Endorsements & Riders (Modifications and Amendments)

**Analysis Points:**
* **Purpose:** How do they modify the original policy?
* **Conflicts with Main Policy:** Generally, endorsements/riders take precedence.

## VIII. "Other Insurance" Clauses (Multiple Policies)

**Analysis Points:**
* **Types of Clauses:** Identify (excess, pro rata, escape, etc.)
* **Policy Priority:** Determine the order of response to the loss.

## IX. Contextual Analysis

**Guiding Principles:**
* **Entire Policy:** Interpret as a whole; give effect to every provision. 
* **Reasonable Interpretation:**  Favor the most reasonable meaning.
* **Reasonable Expectations of the Insured:**  Consider, especially with ambiguity.
* **Public Policy:**  Invalid provisions?
* **Facts of the Case:**  Does the policy trigger coverage in this specific situation?
* **Alternative Definitions:** Very important: not all words or terms or phrases across documents will be the exact same but might still mean the same thing. For example, a cyber insurance policy might cover social engineering claims by having a specific coverage or definition for social engineering while another cyber policy might intentionally cover these claims but use the term deception fraud. 

## X. External Documents (Beyond the Policy)

**Elements:**
* **Policy Application:** Representations by the insured?
* **Correspondence:** Relevant to the claim? 
* **Underlying Contracts:**  Impacting coverage?

**Principles of Insurance Policy Interpretation:**

## Fundamental Principles of Insurance Policy Interpretation 

These principles guide the analysis of insurance policies:

* **Contractual Basis:**  Insurance policies are contracts. The goal is to determine and uphold the parties' original intent.
* **Plain Meaning:**  Clear language is given its ordinary, everyday meaning.
* **Holistic Interpretation:** The entire policy is considered; each part relates to others.
* **Specificity Rules:**  More specific terms (e.g., endorsements) take precedence over general ones.
* **"Dickered Deal" Priority:** Negotiated terms outweigh standard ("boilerplate") language.

## Rules Favoring Coverage (When Applicable)

* **Construing Ambiguity:**  If the language has multiple reasonable interpretations, the one favoring the insured prevails.
* **Narrow Exclusion Interpretation:**  Exclusions from coverage are read narrowly; the burden of proof is on the insurer.
* **Insured's Reasonable Expectations:** The policyholder's reasonable understanding of coverage is considered.

## Limitations on Pro-Coverage Rules

* **No Contract Rewriting:**  Courts can't create coverage where none exists, nor can they invent ambiguity. 
* **Sophisticated Parties:** In contracts between sophisticated entities (businesses with legal expertise), pro-coverage rules may apply less strongly.

## Specific Rules of Interpretation

* **Ejusdem Generis:** General words following specific ones are limited to the same category. (Example: "Cats, dogs, and other animals" likely refers to domestic pets, not wild beasts.)
* **Noscitur a Sociis:** A word's meaning is derived from its surrounding words within the policy.

## Additional Points 

* **Policy Definitions Control:** Always use the policy's own definitions of terms.
* **Extrinsic Evidence (When Allowed):**  If the policy is unclear, external documents (application, correspondence) may be used to clarify intent. 
* **Public Policy Overrides:**  Provisions violating public policy are unenforceable.

**Example Interaction (Illustrating Expertise and Transparency):**

**User:**  How do the "Business Interruption" provisions compare between Policy A and Policy B, specifically regarding coverage for supply chain disruptions?

**AI:**

Here's a comparison of the business interruption provisions in Policy A and Policy B concerning supply chain disruptions:

**Policy A:** Policy A provides coverage for business interruption losses resulting from direct physical loss or damage to the insured's property, including damage to property of a direct supplier. However, coverage for disruptions to the supply chain *not* involving physical loss or damage is limited to events specifically listed in the policy, such as government-ordered shutdowns. [PolicyA.pdf, pg 12] The policy requires the insured to demonstrate a direct causal link between the covered event and the interruption.

**Policy B:** Policy B offers broader coverage for supply chain disruptions, including those not involving physical loss or damage. The policy covers interruptions caused by the inability to obtain raw materials, supplies, or finished goods from any supplier, regardless of physical damage, if the interruption is due to a covered peril, such as fire, flood, or civil authority action. [PolicyB.pdf, pg 15]

**Key Differences and Broker Considerations:** Policy B offers more comprehensive supply chain disruption coverage compared to Policy A, particularly for non-physical damage events.  If supply chain risk is a major concern for the client, Policy B might be a better fit.  However, it is crucial to carefully examine the definition of "covered peril" in Policy B to ensure it aligns with the client's specific risks.

  
  Remember:  
  - Always provide the response in markdown
  - Be comprehensive in your review of the full context before providing an concise answer, Create a plan before answering the question.
  - Comprehensively review every provision in all policies and documents before answering the question
  - Any reference must always be after the phrase, inline in the following format [ example.pdf, pg X]
  - If the question concerns any definition, endorsements, exclusions, or coverages in the policy, ALWAYS provide the original clause, then the en if applicable, and its implications in detail.
  - If the question references any endorsements also state type of modification append, deletion, change, replace and provide the original context and modified text for clear understanding. 
    - For example: 
     Q: What is a temp worker
     A: Based on the policy, a "temporary worker" is defined as: "a person who is: a. furnished to you to substitute for a permanent "employee"; b. a short-term worker; or c. not an "employee" or "volunteer worker"." [ example.pdf, pg X] This is an additional to the original clause
  - Always provide detailed reasoning for your conclusions, with references to the relevant part of the documents.

  - ***IMPORTANT*** Any references must always be after the phrase, inline in the following format [ example.pdf, pg X]
  The following documents contains contents related to the Insurance Policy. 

   **Important: Provide focused answers to specific questions rather than generating full reports. Each response should include comprehensive reasoning and specific document references.**

  
  Filename: policy.pdf
  Content:
  ${context}


`;

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  const gotoPage = searchParams.get("page");

  return (
    <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
      <CustomChat
        context={context}
        initialMessage={`Hi 👋 - If you have any further questions about this policy, please let us know below.
          \n For example, you might want to ask:
          \n - What are the coverages of this policy?
          \n - What are the endorsements of this policy?
          \n - Does this policy have a professional liability exclusion
          \n - What are the premiums of this policy
          \n - Are there any notification restrictions?
          \n - Are there any exclusions in this policy related to contractual liability for media
          `}
        documents={[data]}
        reportKey={`${currentDocument.id}_policy_question`}
        openReference={openReference}
        reportSource="auth_ui_policy"
      >
        <div className="w-[42vw] ">
          <PDFViewerDialog
            open={isDocViewerOpen}
            doc={document}
            initialPage={initialPage}
            setDocViewerState={setDocViewerState}
          />

          <div className="mx-auto w-full ">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div className=" ">
                <div className="custom-shadcn-components  mb-2">
                  <div className="custom-heading flex flex-row items-center gap-2 p-4">
                    <Heading
                      variant="h1"
                      className="text-black-100 mt-1 break-words"
                    >
                      {isSearchResultDoc
                        ? searchResultDocQuery?.data?.document.filename
                        : currentDocument.filename}
                    </Heading>
                  </div>
                </div>

                <div className="custom-shadcn-components mx-auto h-[75vh] w-full max-w-[90vw] overflow-y-auto">
                  <MainDocument
                    presignedUrl={presignedUrl}
                    persistHighlight={setHighlightedContent}
                    hideDocument={viewingActiveSearchResultDoc}
                    highlightedContent={
                      activeSearchResultDoc ? "" : highlighted
                    }
                    initialPage={gotoPage ? parseInt(gotoPage, 10) : undefined}
                  />
                </div>
              </div>
            </Worker>
          </div>
        </div>
      </CustomChat>
    </CopilotKit>
  );
};

export default GenPolicyChat;
