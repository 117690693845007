import {
  ShieldIcon,
  GraduationCapIcon,
  CheckCircleIcon,
  CodeIcon,
  RefreshCwIcon,
  UserCheckIcon,
  ServerIcon,
  UserIcon,
  UsersIcon,
  FileTextIcon,
  TrashIcon,
} from "lucide-react";
import React from "react";

export default function Security() {
  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center overflow-auto text-foreground">
      {/* Hero Component */}
      <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="space-y-2">
              <h1 className="text-3xl font-light tracking-tighter text-black sm:text-4xl md:text-5xl lg:text-6xl/none">
                Security at Qumis
              </h1>
              <p className="mx-auto max-w-[700px] text-gray-400 md:text-xl">
                We take security seriously. Learn about our approach to keeping
                your data safe and secure.
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="w-full border-gray-700" />

      {/* Data Protection Component */}
      <div className="w-full text-foreground">
        <main className="py-12 md:py-24">
          <div className="container mx-auto px-4 md:px-6">
            <div className="mx-auto max-w-4xl space-y-8">
              <div>
                <h1 className="text-3xl font-light tracking-tighter text-black md:text-4xl">
                  Data Protection at Qumis
                </h1>
                <p className="mt-2 text-lg text-gray-300">
                  Learn how Qumis secures your data at rest and in transit, as
                  well as our secret management practices.
                </p>
              </div>
              <div className="space-y-6">
                <div>
                  <h2 className="flex items-center gap-2 text-2xl font-light text-black">
                    <LockIcon className="h-6 w-6" />
                    Data at Rest
                  </h2>
                  <p className="mt-2 text-gray-300">
                    All data stored by Qumis is encrypted at rest using AES-256
                    encryption. We use a secure key management system to manage
                    the encryption keys, ensuring that your data is protected
                    even in the event of a breach.
                  </p>
                </div>
                <div>
                  <h2 className="flex items-center gap-2 text-2xl font-light text-black">
                    <TruckIcon className="h-6 w-6" />
                    Data in Transit
                  </h2>
                  <p className="mt-2 text-gray-300">
                    All communication between your application and Qumis's
                    servers is encrypted using TLS 1.2 or higher. We use strong
                    ciphers and ensure that our SSL/TLS configuration is
                    up-to-date and secure.
                  </p>
                </div>
                <div>
                  <h2 className="flex items-center gap-2 text-2xl font-light text-black">
                    <KeyIcon className="h-6 w-6" />
                    Secret Management
                  </h2>
                  <p className="mt-2 text-gray-300">
                    Qumis uses a secure, enterprise-grade secret management
                    system to store and manage all sensitive information,
                    including API keys, database credentials, and other secrets.
                    This ensures that your data is protected from unauthorized
                    access, even by our own employees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <hr className="w-full border-gray-700" />

      {/* Governance Component */}
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-light tracking-tighter text-black sm:text-5xl">
                Security Governance
              </h2>
              <p className="max-w-[900px] text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Our comprehensive security governance framework ensures that we
                maintain the highest standards of security across our
                organization.
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <ClipboardIcon className="h-6 w-6" />
                Policies and Procedures
              </h3>
              <p className="text-gray-400">
                We have established comprehensive security policies and
                procedures that are regularly reviewed and updated to address
                emerging threats and comply with industry standards.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <ShieldIcon className="h-6 w-6" />
                Risk Management
              </h3>
              <p className="text-gray-400">
                Our risk management process includes regular assessments,
                vulnerability scans, and penetration testing to identify and
                mitigate potential security risks.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <GraduationCapIcon className="h-6 w-6" />
                Employee Training
              </h3>
              <p className="text-gray-400">
                All employees undergo regular security awareness training to
                ensure they understand their role in maintaining the security of
                our systems and your data.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <CheckCircleIcon className="h-6 w-6" />
                Compliance
              </h3>
              <p className="text-gray-400">
                We adhere to industry-standard compliance frameworks and
                regularly undergo third-party audits to verify our security
                practices.
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="w-full border-gray-700" />

      {/* Product Security Component */}
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-light tracking-tighter text-black sm:text-5xl">
                Product Security
              </h2>
              <p className="max-w-[900px] text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Security is built into every aspect of our product development
                lifecycle.
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <CodeIcon className="h-6 w-6" />
                Secure Development Practices
              </h3>
              <p className="text-gray-400">
                Our development team follows secure coding practices and
                undergoes regular security training to ensure that security is
                prioritized throughout the development process.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <RefreshCwIcon className="h-6 w-6" />
                Continuous Security Testing
              </h3>
              <p className="text-gray-400">
                We perform regular security testing, including static and
                dynamic analysis, to identify and address potential
                vulnerabilities before they can be exploited.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <UserCheckIcon className="h-6 w-6" />
                Third-Party Security Audits
              </h3>
              <p className="text-gray-400">
                We engage independent security firms to conduct regular audits
                of our product, ensuring that we maintain the highest standards
                of security.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <ServerIcon className="h-6 w-6" />
                Secure Infrastructure
              </h3>
              <p className="text-gray-400">
                Our product is built on a secure, scalable infrastructure that
                includes multiple layers of protection against various types of
                attacks.
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="w-full border-gray-700" />

      {/* Enterprise Security Component */}
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-light tracking-tighter text-black sm:text-5xl">
                Enterprise-Grade Security
              </h2>
              <p className="max-w-[900px] text-gray-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Qumis provides enterprise-level security features to protect
                your organization's sensitive data.
              </p>
            </div>
          </div>
          <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <UserIcon className="h-6 w-6" />
                Single Sign-On (SSO)
              </h3>
              <p className="text-gray-400">
                We support SSO integration with popular identity providers,
                allowing you to manage access to Qumis through your existing
                authentication systems.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <UsersIcon className="h-6 w-6" />
                Role-Based Access Control (RBAC)
              </h3>
              <p className="text-gray-400">
                Our granular RBAC system allows you to define and manage user
                permissions, ensuring that users only have access to the data
                and features they need.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <FileTextIcon className="h-6 w-6" />
                Audit Logging
              </h3>
              <p className="text-gray-400">
                Comprehensive audit logs track all user activities within the
                system, providing visibility and accountability for all actions
                taken.
              </p>
            </div>
            <div className="flex flex-col justify-center space-y-4">
              <h3 className="flex items-center gap-2 text-xl font-light text-black">
                <TrashIcon className="h-6 w-6" />
                Data Retention and Deletion
              </h3>
              <p className="text-gray-400">
                We offer configurable data retention policies and secure data
                deletion processes to help you comply with data protection
                regulations.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
    </svg>
  );
}

function TruckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2" />
      <path d="M15 18H9" />
      <path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14" />
      <circle cx="17" cy="18" r="2" />
      <circle cx="7" cy="18" r="2" />
    </svg>
  );
}

function KeyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15.5 7.5 2.3 2.3a1 1 0 0 0 1.4 0l2.1-2.1a1 1 0 0 0 0-1.4L19 4" />
      <path d="m21 2-9.6 9.6" />
      <circle cx="7.5" cy="15.5" r="5.5" />
    </svg>
  );
}

function ClipboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
    </svg>
  );
}
