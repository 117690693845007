import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { SingleDocResponseData } from "./api/query_fns/documents";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function fetchDocumentTexts(documents: SingleDocResponseData[]) {
  if (!Array.isArray(documents)) return;

  await Promise.all(
    documents.map(async (doc) => {
      if (doc.document?.textUrl) {
        const textResponse = await fetch(doc.document.textUrl);
        doc.document.text = await textResponse.text();
      }
    })
  );
}
