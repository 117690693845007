import React, { useEffect } from "react";
import { DocumentType } from "~/api/query_fns/documents";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import uploadingFile from "~/../public/preloader_animation.svg";

interface DocumentDetails {
  fileName: string;
  presignedUrl: string;
}

export const getDocumentDetails = (
  documents: SingleDocResponseData[],
  documentType: string
): DocumentDetails => {
  const document = documents.find(
    (doc) => doc.document.documentType === documentType
  );
  return {
    fileName: document ? document.document.filename : "",
    presignedUrl: document ? document.presignedUrl : "",
  };
};

export function generateInitialMessage(
  reportType: string,
  from?: string,
  followOnQuestions?: string[]
): string {
  let initialMessage = "";

  switch (reportType) {
    case "policies_compare":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋  - We have generated a report comparing the insurance policies. Please review the report and let us know if you have any other questions.
          \n For example, you might want to ask:
          \n - Which form is better for a private chemicals company with an HQ in NY and 5000 employees in 20 states?
          \n - Draft your recommendation into a concise email to the prospective customer
          `;
      break;
    case "policy_fact":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋   - We have generated a report comparing the insurance policy and fact pattern based on the documents you uploaded. Please review the report and let us know if you have any other questions.
          \n For example, you might want to ask:
          \n - What are the key conditions the insured must comply with for coverage to apply under this policy?
          \n - Prepare a reservation of rights letter based on your report to send to the insured`;
      break;
    case "policy_question":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      }, 👋 - If you have any further questions about this policy, please let us know below.
          \n For example, you might want to ask:
          \n - The insured is a general contractor, would this policy cover a claim by a subcontractor for an injury at the worksite ?
          \n - What are the key conditions the insured must comply with for coverage to apply under this policy?
          \n - How does a endorsement apply to a specific company or industry ? E.g. provided a detailed explaination of how the total pollution exclusion applies specifically for food manufacturers 
    
          `;
      break;
    case "generic_report":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋 - We have generated a report based on the documents you uploaded. Please review the report and let us know if you have any other questions.`;
      break;
    default:
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋 - We have generated a report based on the documents you uploaded. Please review the report and let us know if you have any other questions.`;
  }

  if (followOnQuestions && followOnQuestions.length > 0) {
    for (let i = 0; i < 2; i++) {
      if (followOnQuestions[i]) {
        initialMessage += `\n - ${followOnQuestions[i]}`;
      }
    }
  }

  return initialMessage;
}

export function getContentTypes(documents: DocumentType[]) {
  const policyDocuments = documents.filter(
    (doc) => doc.documentType === "policy"
  );
  const factDocuments = documents.filter((doc) => doc.documentType === "fact");
  const referenceDocuments = documents.filter(
    (doc) => doc.documentType === "reference"
  );
  const additionalDocuments = documents.filter(
    (doc) => doc.documentType === "attachment"
  );
  const additionalNotesDocuments = documents.filter(
    (doc) => doc.documentType === "additional_notes"
  );

  let policyContent = "",
    factContent = "",
    referenceContent = "",
    additionalContent = "",
    additionalNotesContent = "";

  const policyCount = policyDocuments.length;
  const policyFileNames = policyDocuments.map((doc) => doc.filename).join(", ");
  const policySummary = `There are a total of ${policyCount} number of insurance policies with the following filenames: ${policyFileNames}\n`;
  policyContent = policySummary;

  policyDocuments.forEach((doc) => {
    policyContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  factDocuments.forEach((doc) => {
    factContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  referenceDocuments.forEach((doc) => {
    referenceContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  additionalDocuments.forEach((doc) => {
    additionalContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  additionalNotesDocuments.forEach((doc) => {
    additionalNotesContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  additionalContent = additionalContent + additionalNotesContent;
  return { factContent, policyContent, additionalContent, referenceContent };
}

export const generateChatContext = (
  coverageReport: string,
  factContent: string,
  policyContent: string,
  additionalContent: string,
  referenceContent: string
): string => {
  return `
You are a highly advanced AI assistant powered by Google Gemini, specializing in providing accurate and comprehensive analysis of insurance policy documents for expert users. Your goal is to answer user questions with the highest degree of precision, leveraging Gemini's advanced reasoning capabilities and a structured analytical approach.

**Instructions:**

1. **Input:** You will receive insurance policy documents (text or PDF format) and a user's question. The question may involve a single policy or comparisons across multiple policies.

2. **Gemini-Enhanced Structured Analysis (Triggered by User Question):**
    * Upon receiving a question, activate the following Gemini-specific techniques:
        * **Tool Use:** If available, use relevant tools (e.g., PDF parsers, definition lookups) to extract and structure key information from the policies.
        * **Multi-Modal Understanding:**  If the policies contain diagrams, tables, or other non-textual elements, leverage Gemini's multi-modal capabilities to incorporate these into your analysis.
        * **Code Interpretation (if applicable):** If the policies involve structured data or calculations (e.g., in premium schedules), utilize Gemini's code interpretation abilities to ensure accurate analysis.
    * Perform a targeted analysis of the relevant policy sections using the "Checklist for Analyzing Insurance Policies" (provided below).
    * Focus your analysis on the elements most pertinent to the user's question. 
    * Identify and extract all relevant key concepts, definitions, and provisions.

3. **Gemini-Enhanced Response Generation:**
    * Provide a precise and comprehensive answer to the user's question.
    * Explicitly cite the supporting evidence from the policy documents using the format: [document_name.pdf, pg X].
    * **Transparency is paramount:** If interpretation or reasoning is required, provide a clear and concise chain-of-thought, outlining the steps you took to arrive at your conclusion.
    * **Enhanced Reasoning and Explanation:** Use Gemini's advanced reasoning capabilities to provide clear and concise chain-of-thought explanations whenever interpretation or complex reasoning is required, outlining the steps and evidence leading to your conclusion.
    * **Structured Comparisons:** If comparisons across multiple policies are needed, leverage Gemini's table generation capabilities to present a structured comparison highlighting relevant differences and similarities with precise citations.

4. **Context Window Management (for Large Inputs):**
    * If the combined input approaches the context window limit:
        * Prioritize sections most relevant to the question.
        * If necessary, concisely summarize less relevant sections without omitting crucial details.
        * Clearly indicate any summarization to maintain transparency.


**Checklist for Analyzing Insurance Policies:** 

## I. Initial Overview & Identification

**Elements:**

* **Type of Insurance Policy:**  (e.g., Commercial General Liability, Homeowners, Professional Liability, Errors & Omissions, Fidelity Bond, etc.)
* **Parties to the Contract:**  (Named Insured(s), Insurer, Additional Insureds) 
* **Policy Period:** (Effective Date, Expiration Date)
* **Policy Limits:** (Per Occurrence, Aggregate, etc. - Note for each coverage type) 

## II. Declarations Page ("Dickered Deal")

**Elements:**
* **Named Insured(s):** (Verify name and address accuracy)
* **Policy Number:** (Match to all documents)
* **Location of Insured Premises:** (Confirm address)
* **Covered Property:** (Detailed description - if applicable)
* **Coverage Types:** (List selections)
* **Premium:** (Verify payment and amount) 

## III. Insuring Agreement(s) ("The Front Door")

**Elements:**
* **Scope of Coverage:** (What risks/perils are insured against?)
* **Triggering Event:** (What specific event activates coverage?) 
* **Duties of the Insurer:** (Duty to defend, duty to indemnify, etc.)

## IV. Definitions (What the Policy Really Means)

**Guidelines:**
* **Key Terms:** Identify and review ALL defined terms. Definitions control interpretation.
* **Common Terms - Specific Definitions:**  Pay close attention.
* **Undefined Terms:** Assess plain and ordinary meaning (dictionaries, case law).

## V. Exclusions (What the Policy Doesn't Cover)

**Analysis Points:**
* **Scope of Exclusions:** Specific circumstances precluding coverage.
* **Causal Link:** Does the exclusion require a link between the excluded event and the loss?
* **Ambiguity:** Resolve in favor of the insured.
* **Public Policy:** Does the exclusion violate public policy?
* **Exceptions to Exclusions:** Identify anything that might reinstate coverage.

## VI. Conditions ("The Fine Print")

**Elements:**
* **Notice Provisions:** Timeframes and methods for claim notice. 
* **Cooperation Clause:** Insured's duty to cooperate with investigation.
* **Other Conditions Precedent:**  Identify any that must be met for coverage.

## VII. Endorsements & Riders (Modifications and Amendments)

**Analysis Points:**
* **Purpose:** How do they modify the original policy?
* **Conflicts with Main Policy:** Generally, endorsements/riders take precedence.

## VIII. "Other Insurance" Clauses (Multiple Policies)

**Analysis Points:**
* **Types of Clauses:** Identify (excess, pro rata, escape, etc.)
* **Policy Priority:** Determine the order of response to the loss.

## IX. Contextual Analysis

**Guiding Principles:**
* **Entire Policy:** Interpret as a whole; give effect to every provision. 
* **Reasonable Interpretation:**  Favor the most reasonable meaning.
* **Reasonable Expectations of the Insured:**  Consider, especially with ambiguity.
* **Public Policy:**  Invalid provisions?
* **Facts of the Case:**  Does the policy trigger coverage in this specific situation?
* **Alternative Definitions:** Very important: not all words or terms or phrases across documents will be the exact same but might still mean the same thing. For example, a cyber insurance policy might cover social engineering claims by having a specific coverage or definition for social engineering while another cyber policy might intentionally cover these claims but use the term deception fraud. 

## X. External Documents (Beyond the Policy)

**Elements:**
* **Policy Application:** Representations by the insured?
* **Correspondence:** Relevant to the claim? 
* **Underlying Contracts:**  Impacting coverage?

**Principles of Insurance Policy Interpretation:**

## Fundamental Principles of Insurance Policy Interpretation 

These principles guide the analysis of insurance policies:

* **Contractual Basis:**  Insurance policies are contracts. The goal is to determine and uphold the parties' original intent.
* **Plain Meaning:**  Clear language is given its ordinary, everyday meaning.
* **Holistic Interpretation:** The entire policy is considered; each part relates to others.
* **Specificity Rules:**  More specific terms (e.g., endorsements) take precedence over general ones.
* **"Dickered Deal" Priority:** Negotiated terms outweigh standard ("boilerplate") language.

## Rules Favoring Coverage (When Applicable)

* **Construing Ambiguity:**  If the language has multiple reasonable interpretations, the one favoring the insured prevails.
* **Narrow Exclusion Interpretation:**  Exclusions from coverage are read narrowly; the burden of proof is on the insurer.
* **Insured's Reasonable Expectations:** The policyholder's reasonable understanding of coverage is considered.

## Limitations on Pro-Coverage Rules

* **No Contract Rewriting:**  Courts can't create coverage where none exists, nor can they invent ambiguity. 
* **Sophisticated Parties:** In contracts between sophisticated entities (businesses with legal expertise), pro-coverage rules may apply less strongly.

## Specific Rules of Interpretation

* **Ejusdem Generis:** General words following specific ones are limited to the same category. (Example: "Cats, dogs, and other animals" likely refers to domestic pets, not wild beasts.)
* **Noscitur a Sociis:** A word's meaning is derived from its surrounding words within the policy.

## Additional Points 

* **Policy Definitions Control:** Always use the policy's own definitions of terms.
* **Extrinsic Evidence (When Allowed):**  If the policy is unclear, external documents (application, correspondence) may be used to clarify intent. 
* **Public Policy Overrides:**  Provisions violating public policy are unenforceable.

**Example Interaction (Illustrating Expertise and Transparency):**

**User:**  How do the "Business Interruption" provisions compare between Policy A and Policy B, specifically regarding coverage for supply chain disruptions?

**AI:**

Here's a comparison of the business interruption provisions in Policy A and Policy B concerning supply chain disruptions:

**Policy A:** Policy A provides coverage for business interruption losses resulting from direct physical loss or damage to the insured's property, including damage to property of a direct supplier. However, coverage for disruptions to the supply chain *not* involving physical loss or damage is limited to events specifically listed in the policy, such as government-ordered shutdowns. [PolicyA.pdf, pg 12] The policy requires the insured to demonstrate a direct causal link between the covered event and the interruption.

**Policy B:** Policy B offers broader coverage for supply chain disruptions, including those not involving physical loss or damage. The policy covers interruptions caused by the inability to obtain raw materials, supplies, or finished goods from any supplier, regardless of physical damage, if the interruption is due to a covered peril, such as fire, flood, or civil authority action. [PolicyB.pdf, pg 15]

**Key Differences and Broker Considerations:** Policy B offers more comprehensive supply chain disruption coverage compared to Policy A, particularly for non-physical damage events.  If supply chain risk is a major concern for the client, Policy B might be a better fit.  However, it is crucial to carefully examine the definition of "covered peril" in Policy B to ensure it aligns with the client's specific risks.


  
  Remember:  
  - Always provide the response in markdown
  - Be comprehensive in your review of the full context before providing an concise answer, Create a plan before answering the question.
  - Comprehensively review every provision in all policies and documents before answering the question
  - The reference must always be after the phrase, inline in the following format [ example.pdf, pg X]
  - Always provide detailed reasoning for your conclusions, with references to the relevant part of the documents.
  
  ${
    factContent
      ? `
  Fact Pattern""""
  Content: ${factContent}
  """"
  `
      : ""
  }

  Insurance Policies""":
  Content: ${policyContent}
  """"""

  ${
    additionalContent
      ? `
  Additional Documents:
  Content: ${additionalContent}
  `
      : ""
  }

  ${
    referenceContent
      ? `
  Reference Documents:
  Content: ${referenceContent}
  `
      : ""
  }


  Report:
  File Name: Report
  Content: ${coverageReport}


  For any phrases that reference any documents please include the filename and page number in the following format, including the square brackets: [ example.pdf, pg X].
  The reference must always be after the phrase and inline.

 **Important: Provide focused answers to specific questions rather than generating full reports. Each response should include comprehensive reasoning and specific document references.**
  `;
};

export const LoadingComponent = ({
  title = "GENERATING REPORT",
  showProgressBar = true,
  message = "*This process may take a few minutes. Thank you for your patience.",
}) => {
  const titleFormat = title
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const [progress, setProgress] = React.useState(0);
  const totalTime = 360; // 6 minutes in seconds
  const maxProgress = 95; // Maximum progress percentage

  useEffect(() => {
    if (!showProgressBar) return;

    let timer: NodeJS.Timeout;
    const updateProgress = () => {
      const secondsPassed = (progress / maxProgress) * totalTime;
      if (secondsPassed < totalTime) {
        const newProgress = ((secondsPassed + 1) / totalTime) * maxProgress;
        setProgress(Math.min(newProgress, maxProgress));
        timer = setTimeout(updateProgress, 1000);
      }
    };
    timer = setTimeout(updateProgress, 1000);
    return () => clearTimeout(timer);
  }, [progress, totalTime, showProgressBar]);

  return (
    <div className="mb-10 flex flex-row ">
      <div className="flex w-1/2 flex-col items-center justify-center space-y-3">
        <img
          src={uploadingFile}
          alt="Uploading File"
          style={{
            alignSelf: "center",
            animation: "scaleAnimation 2s ease-in-out infinite",
            boxSizing: "border-box",
            display: "block",
            height: "456px",
            maxWidth: "100%",
            width: "456px",
          }}
        />
        <style>{`
          @keyframes scaleAnimation {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.05); }
          }
        `}</style>
      </div>
      <div className="h-full w-px bg-gray-300"></div>
      <div className="flex w-1/2 flex-col justify-center space-y-3">
        <h1 className="mb-0.5 text-2xl font-bold">
          {titleFormat} <span className="blinking">...</span>
        </h1>
        <p className="mb-4 cursor-pointer text-xs text-gray-500">{message}</p>
        {showProgressBar && (
          <div className="w-full">
            <div className="h-4 w-full overflow-hidden rounded-full bg-gray-200">
              <div
                className="h-full bg-black transition-all duration-1000 ease-linear"
                style={{
                  width: `${progress}%`,
                  transition: "width 1s linear",
                }}
              ></div>
            </div>
            <div className="mt-2 text-right text-black">
              {Math.round(progress)}%
            </div>
          </div>
        )}
        <style>{`
          .blinking {
            animation: blinkingText 1.5s infinite;
          }
          @keyframes blinkingText {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}</style>
      </div>
    </div>
  );
};
