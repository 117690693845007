import { useNavigate } from "react-router-dom";
import useSession from "~/hooks/useSession";
import useFeatureFlags from "~/hooks/useFeatureFlags";
import { AuthBoxCenterText } from "~/components/AuthBox";
import StripePricingTable from "~/components/StripePricingTable";

const SignUpPlan = () => {
  const featureFlags = useFeatureFlags();
  const { session } = useSession();
  const navigate = useNavigate();

  // todo:(redirect users that already have a subscription to the customer portal)
  if (!featureFlags.signupBilling) {
    navigate("/");
    return;
  }

  if (!session) {
    navigate("/log_in");
    return;
  }

  return (
    <article>
      <AuthBoxCenterText>Choose your policy plan</AuthBoxCenterText>
      <div className="mx-auto w-[680px] py-6">
        <StripePricingTable
          client-reference-id={session.organizationId}
          customer-email={session.email}
          pricing-table-id="prctbl_1OYu4fC188a5Lc3aRPcpraJU"
          publishable-key="pk_test_51OYtb4C188a5Lc3ae3R0lj5f21RxKnzzTjMs47bxcmERB97CPONX8XdsvKI3Z7H92shw4bopj4Zkzy4sZzXfemUY00i5kdtZc6"
        />
      </div>
    </article>
  );
};

export default SignUpPlan;
