import { apiFn } from "~/api/utils";

export interface UserData {
  id: string;
  email: string;
  role: string;
  createdAt: string;
}

export const getUsers = apiFn<void, { users: UserData[] }>("GET", "/users");

export const createUser = apiFn<
  { user: { password: string; inviteId: string } },
  { user: UserData }
>("POST", "/users");

export const getMe = apiFn<void, { user: UserData }>("GET", "/users/me");

export const updateMe = apiFn<
  { user: { password?: string; email?: string; current_password?: string } },
  { user: UserData }
>("PUT", "/users/me");

export const updateUser = apiFn<
  { id: string; user: { role?: string } },
  { user: UserData }
>("PUT", (params) => `/users/${params.id}`);
