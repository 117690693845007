import { Button } from "~/components/ui/button";
const apiUrl = import.meta.env.VITE_API_URL;

{
  /* todo: check if an org has a plan first. And then maybe only show this button if they have a stripe customer id */
}
const ManageBillingForm = ({ children }: { children: React.ReactNode }) => (
  <form method="POST" action={`${apiUrl}/stripe_customer_portal_session`}>
    {children}
  </form>
);

export const ManageBillingLink = () => (
  <ManageBillingForm>
    <Button variant={null} className="underline hover:no-underline">
      Manage Billing
    </Button>
  </ManageBillingForm>
);

export const ManageBillingButton = () => (
  <ManageBillingForm>
    <Button variant="default">Manage Billing</Button>
  </ManageBillingForm>
);
