import { Worker } from "@react-pdf-viewer/core";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { getDocument } from "~/api";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";
import { format } from "date-fns";
import { Button } from "~/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "~/components/ui/card";
import DeleteLink from "./DocumentsShow/DeleteLink";
import useSession from "~/hooks/useSession";
import { DownloadIcon } from "@radix-ui/react-icons";
import { BreadcrumbBar, BreadcrumbItem } from "~/components/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "~/components/ui/link";

interface LoaderParams {
  params: {
    id?: string;
  };
}

type DocumentShowLoaderResponse = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderParams) => {
  return await getDocument({ id: params.id || "" });
};

const DocumentShow = () => {
  const [searchParams] = useSearchParams();
  const initialPage = searchParams.get("page");
  const { presignedUrl, document } =
    useLoaderData() as DocumentShowLoaderResponse;

  const { session } = useSession();
  const role = session?.role || "";

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div className="grid h-full grid-cols-5 grid-rows-[auto] gap-4 pb-12">
        <section className="col-span-5 mt-8 ">
          <BreadcrumbBar>
            <BreadcrumbItem>
              <Link asChild>
                <RouterLink to="/documents">Documents</RouterLink>
              </Link>
            </BreadcrumbItem>
          </BreadcrumbBar>
          <Heading variant="h1" className="mt-2">
            {document.filename}
          </Heading>
        </section>
        <MainDocument
          presignedUrl={presignedUrl}
          persistHighlight={() => null}
          hideDocument={false}
          highlightedContent={""}
          initialPage={initialPage ? parseInt(initialPage, 10) : undefined}
        />
        <section className="col-span-2 mt-4">
          <Card>
            <CardHeader>
              <CardTitle>Details</CardTitle>
            </CardHeader>
            <CardContent className="text-muted-foreground">
              {document.organizationName === "Qumis" ? (
                <Paragraph variant="medium">
                  Document from Qumis Database
                </Paragraph>
              ) : (
                <>
                  <Paragraph variant="medium">
                    Created on{" "}
                    {format(new Date(document.createdAt), "MMM d, yyyy")}
                  </Paragraph>
                  <Paragraph variant="medium">
                    Uploaded by {document.uploaderEmail}
                  </Paragraph>
                </>
              )}
            </CardContent>
            <CardFooter>
              <Button variant="default" asChild>
                <a
                  href={presignedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <DownloadIcon className="mr-1" />
                  Download file
                </a>
              </Button>
              {["site_admin", "admin", "owner"].includes(role) &&
                document.organizationName === session?.organizationName && (
                  <div className="ml-8">
                    <DeleteLink document={document} />
                  </div>
                )}
            </CardFooter>
          </Card>
        </section>
      </div>
    </Worker>
  );
};

export default DocumentShow;
