import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { Input } from "~/components/ui/input";

export const AuthContainer = styled.div(
  tw`flex h-full flex-col justify-center`
);
export const AuthBox = styled.div(
  tw`mx-auto p-5 pb-10 mb-5 flex w-full sm:(min-w-[550px] w-auto) flex-col justify-center space-y-6 border border-translucent rounded backdrop-blur bg-translucent-less`
);

export const AuthBoxLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => (
  <Link
    to={to}
    target="_blank"
    rel="noopener noreferrer"
    className="underline underline-offset-4 hover:text-primary"
  >
    {children}
  </Link>
);

export const AuthBoxCenterText = styled.p(
  tw`text-center text-sm text-text-on-dark`
);

export const AuthInput = styled(Input)(
  tw`border-translucent bg-translucent-less text-text-on-dark`
);
