import tw from "twin.macro";
import styled from "styled-components";

import sanitizeHtml from "sanitize-html";

export const sanitizeSearchHighlights = (str: string) => {
  const subbed = str
    .replaceAll("</SEARCHHIGHLIGHT> <SEARCHHIGHLIGHT>", " ")
    .replaceAll("<SEARCHHIGHLIGHT>", "SEARCHHIGHLIGHTOPEN")
    .replaceAll("</SEARCHHIGHLIGHT>", "SEARCHHIGHLIGHTCLOSE");

  const sanitized = sanitizeHtml(subbed, { allowedTags: [] });

  return sanitized
    .replaceAll("SEARCHHIGHLIGHTOPEN", "<span class='font-bold'>")
    .replaceAll("SEARCHHIGHLIGHTCLOSE", "</span>");
};

export const stripSearchHighlights = (str: string) =>
  str
    .replaceAll("</SEARCHHIGHLIGHT> <SEARCHHIGHLIGHT>", " ")
    .replaceAll("<SEARCHHIGHLIGHT>", "")
    .replaceAll("</SEARCHHIGHLIGHT>", "");

const containerVariants = {
  semantic: tw`bg-highlight-semantic`,
  lexical: tw`bg-highlight-lexical`,
};
interface ContainerProps {
  variant?: keyof typeof containerVariants;
}
const StyledMatchHighlight = styled.div<ContainerProps>(
  tw`overflow-hidden rounded-md p-4 text-sm`,
  ({ variant = "semantic" }) => containerVariants[variant]
);

export default StyledMatchHighlight;
