import { createColumnHelper } from "@tanstack/react-table";
import { Form, useLoaderData, useNavigation } from "react-router-dom";
import { format } from "date-fns";
import { getInvites, createInvite, getUsers, updateUser } from "~/api";
import { DataTable } from "~/components/ui/data-table";
import { Input } from "~/components/ui/input";
import { InviteData } from "~/api/query_fns/invites";
import { UserData } from "~/api/query_fns/users";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import { Heading } from "~/components/ui/heading";
import InviteLink from "./Members/InviteLink";
import useSession from "~/hooks/useSession";
import { useMutation } from "react-query";
import RoleCell from "./Members/RoleCell";
import { ColumnDef } from "@tanstack/react-table";

export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);

  const email = data.email as string;

  const invite = await createInvite({ email });

  return invite;
};

export const loader = async () => {
  const invites_req = await getInvites();
  const users_req = await getUsers();

  return { invites: invites_req.invites, users: users_req.users };
};

const isInvite = (row: InviteData | UserData): row is InviteData => {
  return (row as InviteData).claimedAt !== undefined;
};

const Members = () => {
  const { invites, users } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;
  const columnHelper = createColumnHelper<InviteData | UserData>();
  const navigation = useNavigation();
  const { session } = useSession();

  const setRole = async ({ id, role }: { id: string; role: string }) => {
    await updateUser({ id, user: { role } });
  };

  const { mutate } = useMutation(setRole);

  const filteredInvites = invites.filter(
    (invite) => !users.some((user) => user.email === invite.email)
  );

  const tableData = [...filteredInvites, ...users].sort((a, b) =>
    a.email.localeCompare(b.email)
  );

  const isSubmitting = navigation.state === "submitting";

  const columns: ColumnDef<InviteData | UserData, string>[] = [
    columnHelper.accessor("email", {
      header: "Email",
    }),
    columnHelper.display({
      header: "Role",
      id: "role",
      cell: ({ row }) => (
        <RoleCell row={row} mutate={mutate} sessionRole={session?.role || ""} />
      ),
    }),
    columnHelper.display({
      header: "Joined",
      id: "joined",
      cell: ({ row }) => {
        if (isInvite(row.original))
          return <div className="text-xs text-gray-500">invite pending</div>;

        const dateObj = new Date(row.original.createdAt);
        const formattedDate = format(dateObj, "MMM d, yyyy");

        return <div className="text-xs text-gray-500">{formattedDate}</div>;
      },
    }),
    columnHelper.display({
      header: "Invite",
      id: "invite",
      cell: ({ row }) => {
        if (isInvite(row.original)) {
          return (
            <InviteLink
              id={row.original.id}
              canDelete={!row.original.claimedAt}
            />
          );
        }
      },
    }),
  ];

  return (
    <div className="custom-shadcn-components min-h-[75vh]">
      <div className="mb-4">
        <Heading as="h2" className="text-black">
          Members of {session?.organizationName}
        </Heading>
      </div>

      <Form method="post" className="mb-4 flex">
        <Input
          className="mr-2 w-64"
          name="email"
          id="email"
          placeholder="Email address"
          data-1p-ignore
        />
        <ButtonWithLoader loading={isSubmitting}>Invite</ButtonWithLoader>
      </Form>

      <div className="bg-white">
        <DataTable columns={columns} data={tableData} />
      </div>
    </div>
  );
};

export default Members;
