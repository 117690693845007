import { redirect, useNavigation } from "react-router-dom";
import { useState } from "react";
import { createDocument } from "~/api";
import UploadForm from "./Analyze/UploadForm";
import UCTemplate from "~/components/UCTemplate";
import UCPolicyListComponent from "~/components/UCPolicyListComponent";
import { Heading } from "~/components/ui/heading";

type FormValue = { file: File; claimNumber: string; claimColor: string };

export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData) as FormValue;

  const file = data.file;
  const filename = file.name;
  const filetype = file.type;

  const { document, presignedUrl } = await createDocument({
    filename,
    document_type: "policy",
    claim_number: data.claimNumber,
    claim_color: data.claimColor,
  });

  await fetch(presignedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": filetype,
    },
    body: file,
  });

  return redirect(`/chat/${document.id}`);
};

const NewPolicy = ({
  setLoading,
}: {
  setLoading: (loading: boolean) => void;
}) => {
  const [file, setFile] = useState<File | null>(null);
  const navigation = useNavigation();
  const isPending =
    navigation.state === "submitting" || navigation.state === "loading";

  return (
    <div className="h-full w-full ">
      <Heading
        as="h2"
        className="custom-heading text-black-100 mb-4 flex flex-row items-center gap-2"
      >
        New Policy
      </Heading>

      <UploadForm
        setFile={setFile}
        disableSubmit={!file}
        submitting={isPending}
        buttonName={"Chat with Policy"}
        setLoading={setLoading}
      />
    </div>
  );
};

const UCPolicyChat = () => {
  const [loading, setLoading] = useState(false);

  return (
    <UCTemplate
      LeftComponent={() => (
        <UCPolicyListComponent linkPrefix="/chat/" itemsPerPage={5} />
      )}
      RightComponent={() => <NewPolicy setLoading={setLoading} />}
      loadingTitle="ANALYZING POLICY"
      isLoading={loading}
      enableLoading={false}
    />
  );
};

export default UCPolicyChat;
