import { Button } from "~/components/ui/button";
import { CopyIcon, TrashIcon } from "lucide-react";
import { Form, Link } from "react-router-dom";

interface InviteLinkProps {
  id: string;
  canDelete?: boolean;
}

const InviteLink = ({ id, canDelete = false }: InviteLinkProps) => {
  const host = window.location.host;
  const path = `/invites/${id}`;
  const url = `https://${host}${path}`;

  const handleCopy = async () => {
    await navigator.clipboard.writeText(url);
  };

  return (
    <div className="flex flex w-3/4 items-center">
      <Link to={path} className="link-primary mr-2">
        Link
      </Link>
      <Button
        variant="ghost"
        onClick={handleCopy}
        className="h-auto border border-transparent p-1 focus:border-gray-900 focus:bg-purple-100"
      >
        <CopyIcon className="h-4 w-4" />
      </Button>
      {canDelete && (
        <Form
          method="post"
          className="px-2"
          action={`/admin/invites/${id}/delete`}
        >
          <Button
            variant="ghost"
            type="submit"
            className="h-auto border border-transparent bg-red-500 p-1 text-black hover:bg-red-400 hover:text-black focus:border-gray-900 focus:bg-red-700"
          >
            <TrashIcon className="h-4 w-4" />
          </Button>
        </Form>
      )}
    </div>
  );
};

export default InviteLink;
