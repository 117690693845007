import { useEffect, useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { searchPlugin, SearchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";

type MainDocumentColumnProps = {
  presignedUrl: string;
  persistHighlight: () => void;
  hideDocument: boolean;
  highlightedContent: string | null;
  initialPage?: number;
};

const MainDocumentColumn = ({
  presignedUrl,
  persistHighlight,
  hideDocument,
  highlightedContent,
  initialPage,
}: MainDocumentColumnProps) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const handleDocumentLoad = () => setDocumentLoaded(true);
  const pageNavigationPluginInstance = pageNavigationPlugin({
    enableShortcuts: false,
  });
  const [currentPage, setCurrentPage] = useState(initialPage || 0);
  const { jumpToPage } = pageNavigationPluginInstance;

  const searchPluginInstance: SearchPlugin = searchPlugin({
    renderHighlights: (renderProps) => {
      return (
        <>
          {renderProps.highlightAreas.map((area, index) => (
            <div
              key={`${area.pageIndex}-${index}`}
              className={"absolute bg-highlight-semantic mix-blend-multiply"}
              style={{
                ...renderProps.getCssProperties(area),
              }}
            />
          ))}
        </>
      );
    },
    ...(highlightedContent ? { keyword: [highlightedContent] } : {}),
  });
  const { highlight, clearHighlights, setTargetPages } = searchPluginInstance;

  // programmatically highlight words based on persisted selection
  useEffect(() => {
    if (!isDocumentLoaded) {
      return;
    }

    if (highlightedContent) {
      setTargetPages((targetPage) =>
        // going back from search results deducts a page for unknown reasons
        [currentPage, currentPage + 1].includes(targetPage.pageIndex)
      );
      highlight({
        keyword: highlightedContent,
      });
    } else {
      clearHighlights();
    }
  }, [highlightedContent, isDocumentLoaded]);

  // jump to initial page if one is set in the url
  useEffect(() => {
    if (initialPage && isDocumentLoaded) {
      jumpToPage(initialPage - 1);
    }
  }, [initialPage, isDocumentLoaded]);

  useEffect(() => {
    if (initialPage && isDocumentLoaded) {
      jumpToPage(initialPage - 1);
    }
  }, [initialPage, isDocumentLoaded]);

  return (
    <div
      className="col-span-3 h-[85vh] w-full"
      style={{ display: hideDocument ? "none" : "block" }}
      onMouseUp={persistHighlight}
    >
      <Viewer
        fileUrl={presignedUrl}
        plugins={[pageNavigationPluginInstance, searchPluginInstance]}
        onDocumentLoad={handleDocumentLoad}
        onPageChange={(e) => setCurrentPage(e.currentPage)}
      />
    </div>
  );
};

export default MainDocumentColumn;
