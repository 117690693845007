import { useState } from "react";
import { InviteData } from "~/api/query_fns/invites";
import { UserData } from "~/api/query_fns/users";
import { Row } from "@tanstack/react-table";

interface RoleCellProps {
  row: Row<InviteData | UserData>;
  mutate: ({ id, role }: { id: string; role: string }) => void;
  sessionRole: string;
}

const RoleCell: React.FC<RoleCellProps> = ({ row, mutate, sessionRole }) => {
  const [role, setRole] = useState(
    "role" in row.original ? row.original.role : "user"
  );

  const canSetRole =
    ["site_admin", "owner"].includes(sessionRole) &&
    ["user", "admin"].includes(role) &&
    "role" in row.original;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    mutate({ id: row.original.id, role: e.target.value });
    if ("role" in row.original) row.original.role = e.target.value;
    setRole(e.target.value);
  };

  if (canSetRole) {
    return (
      <select value={role} onChange={handleChange}>
        <option value="user">user</option>
        <option value="admin">admin</option>
      </select>
    );
  } else {
    return <div>{role}</div>;
  }
};

export default RoleCell;
