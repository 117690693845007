import { CheckCircleIcon } from "lucide-react";
import { createColumnHelper } from "@tanstack/react-table";
import { Form, useLoaderData, useNavigation } from "react-router-dom";
import { getOrganizations } from "~/api";
import {
  ResponseData as OrganizationData,
  inviteOrganization,
  updateOrganization,
} from "~/api/query_fns/organizations";
import { Input } from "~/components/ui/input";
import { DataTable } from "~/components/ui/data-table";
import { Heading } from "~/components/ui/heading";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import InviteLink from "./Members/InviteLink";

type InviteFormValues = { name: string; ownerEmail: string };
const handleOnInvite = async ({ name, ownerEmail }: InviteFormValues) => {
  const organization = await inviteOrganization({
    organization: { name, ownerEmail },
  });

  return organization;
};

type ChangePlanFormValues = { id: string; newPlan: string };
const handleChangePlan = async ({ id, newPlan }: ChangePlanFormValues) => {
  const organization = await updateOrganization({
    id,
    organization: { plan: newPlan },
  });

  return organization;
};

export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);

  const intent = formData.get("intent");

  if (intent === "invite") {
    return handleOnInvite(data as InviteFormValues);
  }

  if (intent === "changePlan") {
    return handleChangePlan(data as ChangePlanFormValues);
  }

  throw new Error("Invalid intent");
};

export const loader = async () => {
  return await getOrganizations();
};

const Organizations = () => {
  const { organizations } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;
  const columnHelper = createColumnHelper<OrganizationData>();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === "submitting";

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("plan", {
      header: "Plan",
    }),
    columnHelper.display({
      header: "Change Plan",
      id: "changePlan",
      cell: ({ row }) => (
        <Form method="put" className="flex flex-row items-center gap-2">
          <Input type="hidden" name="id" value={row.original.id} />
          <Input
            type="hidden"
            name="newPlan"
            value={row.original.plan === "standard" ? "enterprise" : "standard"}
          />
          <ButtonWithLoader
            loading={isSubmitting}
            name="intent"
            value="changePlan"
            size="xs"
            className="px-4"
          >
            {row.original.plan === "standard" ? "Upgrade" : "Downgrade"} Plan
          </ButtonWithLoader>
        </Form>
      ),
    }),
    columnHelper.accessor("userCount", {
      header: "Users",
    }),
    columnHelper.accessor("documentCount", {
      header: "Documents",
    }),
    columnHelper.display({
      header: "Owner Email",
      id: "email",
      cell: ({ row }) => {
        const invite = row.original.initialInvite;
        const owner = row.original.owner;

        if (owner) {
          return <div>{owner.email}</div>;
        } else if (invite && !invite.claimedAt) {
          return <div className="text-gray-500">{invite.email}</div>;
        } else {
          return <div></div>;
        }
      },
    }),
    columnHelper.display({
      header: "Invite",
      id: "invite",
      cell: ({ row }) => {
        const invite = row.original.initialInvite;

        if (invite === null) {
          return <div></div>;
        } else if (invite.claimedAt !== null) {
          return (
            <div>
              <CheckCircleIcon className="h-4 w-4 text-green-600" />
            </div>
          );
        } else {
          return <InviteLink id={invite.id} />;
        }
      },
    }),
  ];

  return (
    <div className="custom-shadcn-components min-h-[75vh]">
      <div className="mb-4 ">
        <Heading as="h2" className="text-black">
          Organizations
        </Heading>
      </div>

      <Form method="post" className="mb-4 flex">
        <Input
          className="mr-2 w-64"
          name="name"
          id="name"
          placeholder="Organization name"
          data-1p-ignore
          data-testid="inviteOrganizationName"
        />
        <Input
          className="mr-2 w-64"
          name="ownerEmail"
          id="ownerEmail"
          placeholder="Owner email address"
          data-1p-ignore
          data-testid="inviteOrganizationEmail"
        />
        <ButtonWithLoader loading={isSubmitting} name="intent" value="invite">
          Send Invite
        </ButtonWithLoader>
      </Form>

      <div className="bg-white">
        {/* https://github.com/TanStack/table/issues/4241 :( */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <DataTable columns={columns as any} data={organizations} />
      </div>
    </div>
  );
};

export default Organizations;
