import { useState } from "react";
import { SearchResultMatch } from "~/api/query_fns/search";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import Match from "./Match";

interface SearchResultProps {
  id: string | null;
  filename: string;
  matches: SearchResultMatch[];
  uploaderEmail: string;
  organizationName: string;
  setActiveSearchResultDoc: (
    doc: {
      id: string;
      page: number;
      highlight?: string;
      matchType?: string;
    } | null
  ) => void;
}

const SearchResult = ({
  id,
  filename,
  matches,
  uploaderEmail,
  organizationName,
  setActiveSearchResultDoc,
}: SearchResultProps) => {
  const topMatches = matches.slice(0, 3);
  const moreMatches = matches.slice(3);

  const [showMore, setShowMore] = useState(false);

  const visibleMatches = showMore ? matches : topMatches;

  return (
    <Card className="mb-4 overflow-hidden">
      <CardHeader className="pb-3">
        {id ? (
          <CardTitle
            className="cursor-pointer hover:underline"
            onClick={() => setActiveSearchResultDoc({ id: id, page: 1 })}
          >
            {filename}
          </CardTitle>
        ) : (
          <CardTitle>{filename}</CardTitle>
        )}
      </CardHeader>

      <CardContent className="overflow-hidden pb-2">
        <CardDescription className="text-xs">
          {organizationName === "Qumis"
            ? "Qumis Database"
            : `Uploaded by ${uploaderEmail}`}
        </CardDescription>
        <CardDescription className="py-3">Matches:</CardDescription>
        {visibleMatches.map((match, i) => (
          <Match
            key={i}
            match={match}
            setActiveSearchResultDoc={setActiveSearchResultDoc}
            documentId={id}
          />
        ))}
        {moreMatches.length > 0 && !showMore && (
          <div className="mb-4">
            <Button
              variant="link"
              className="p-0 text-sm text-gray-500"
              onClick={() => setShowMore(true)}
            >
              Show {moreMatches.length} more match
              {moreMatches.length > 1 && "es"} in this document
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default SearchResult;
