import { useEffect } from "react";
import useSession from "~/hooks/useSession";
import useFeatureFlags from "~/hooks/useFeatureFlags";
import { AuthBoxCenterText, AuthBoxLink } from "~/components/AuthBox";
import { Button } from "~/components/ui/button";
import { useForm, SubmitHandler } from "react-hook-form";
import tw from "twin.macro";
import styled from "styled-components";
import { AuthInput } from "~/components/AuthBox";
import { Label } from "~/components/ui/label";
import { useNavigate, Link, redirect, useSubmit } from "react-router-dom";
import { QueryClient } from "react-query";

import { createOrganization } from "~/api/query_fns/organizations";

type SignUpInputs = {
  organizationName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsOfService: boolean;
};

const ErrorMessage = styled.p(tw`text-destructive text-sm`);

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as unknown as SignUpInputs;

    try {
      await createOrganization({
        organization: {
          name: data.organizationName,
          ownerEmail: data.email,
          password: data.password,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        return {
          status: 400,
          error: e.message,
        };
      }
    }

    await queryClient.invalidateQueries({ queryKey: ["session"] });

    return redirect("/sign-up-plan");
  };

const SignUp = () => {
  const featureFlags = useFeatureFlags();
  const { loggedIn } = useSession();
  const navigate = useNavigate();
  const submit = useSubmit();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SignUpInputs>();

  const onSubmit: SubmitHandler<SignUpInputs> = async (data) => {
    submit(data, { method: "post" });
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn, navigate]);

  if (!featureFlags.signupBilling) {
    navigate("/");
    return;
  }

  return (
    <>
      <AuthBoxCenterText>Sign up</AuthBoxCenterText>
      <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col gap-4">
        <div>
          <AuthInput
            placeholder="Organization Name"
            aria-label="Organization Name"
            {...register("organizationName", {
              required: "Organization name is required.",
            })}
          />
          {errors.organizationName && (
            <ErrorMessage>{errors.organizationName.message}</ErrorMessage>
          )}
        </div>
        <div>
          <AuthInput
            placeholder="Email"
            aria-label="Email"
            {...register("email", {
              required: "Email is required.",
              pattern: {
                value: /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/,
                message: "Must be a valid email address.",
              },
            })}
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </div>
        <div>
          <AuthInput
            placeholder="Password"
            aria-label="Password"
            {...register("password", {
              required: "Password is required.",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
              pattern: {
                value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                message:
                  "Password must include at least 1 number, 1 uppercase letter, and 1 lowercase",
              },
            })}
            type="password"
          />
          {errors.password && (
            <ErrorMessage>{errors.password.message}</ErrorMessage>
          )}
        </div>
        <div>
          <AuthInput
            placeholder="Confirm Password"
            aria-label="Confirm Password"
            {...register("confirmPassword", {
              validate: (v) =>
                v === getValues("password") || "Passwords must match",
            })}
            type="password"
          />
          {errors.confirmPassword && (
            <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
          )}
        </div>
        <div>
          <div className="flex flex-row gap-1">
            <input
              type="checkbox"
              {...register("termsOfService", { required: true })}
            />
            <Label
              className="ml-1 font-normal text-muted-foreground"
              htmlFor="termsOfService"
            >
              I agree to the{" "}
              <AuthBoxLink to="https://www.qumis.ai/legal/terms-of-service">
                Terms of Service
              </AuthBoxLink>{" "}
              and{" "}
              <AuthBoxLink to="https://www.qumis.ai/legal/privacy-policy">
                Privacy Policy
              </AuthBoxLink>
            </Label>
          </div>
          {errors.termsOfService && (
            <ErrorMessage>
              You must agree to the terms of service and privacy policy
            </ErrorMessage>
          )}
        </div>
        <Button tw="w-full">Next</Button>
      </form>
      <>
        <hr className="opacity-20" />
        <AuthBoxCenterText tw="opacity-50 text-xs">
          Have an account already?
        </AuthBoxCenterText>

        <AuthBoxCenterText>
          <Button asChild>
            <Link to="/" className="w-full">
              Log in
            </Link>
          </Button>
        </AuthBoxCenterText>
      </>
    </>
  );
};

export default SignUp;
