import React from "react";
import { LoadingComponent } from "~/utils/util-report";

interface UCTemplateProps {
  LeftComponent: React.ComponentType;
  RightComponent: React.ComponentType;
  isLoading?: boolean;
  loadingTitle?: string;
  enableLoading?: boolean;
}

const UCTemplate: React.FC<UCTemplateProps> = ({
  LeftComponent,
  RightComponent,
  isLoading = false,
  loadingTitle = "LOADING",
  enableLoading = true,
}) => {
  if (isLoading && enableLoading) {
    return <LoadingComponent title={loadingTitle} />;
  }

  return (
    <div className="mt-10 flex h-[80vh] items-stretch justify-center gap-8 p-8">
      <div className="custom-shadcn-components max-h-[600px] min-h-[400px] min-w-[200px] max-w-[600px] flex-1 overflow-hidden">
        <div className="h-full w-full p-4">
          <LeftComponent />
        </div>
      </div>
      <div className="custom-shadcn-components flex max-h-[600px] min-h-[400px] min-w-[200px] max-w-[600px] flex-1 items-center justify-center overflow-hidden">
        <div className="h-full w-full p-4">
          <RightComponent />
        </div>
      </div>
    </div>
  );
};

export default UCTemplate;
