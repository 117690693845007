import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import * as FullStory from "@fullstory/browser";
import useSession from "~/hooks/useSession";
import { createSession } from "~/api";
import { Destructive } from "~/components/Alert";
import LoginForm from "./LogIn/LoginForm";
import { AuthBoxCenterText, AuthBoxLink } from "~/components/AuthBox";
import { Button } from "~/components/ui/button";
import useFeatureFlags from "~/hooks/useFeatureFlags";
import { Link } from "react-router-dom";

import "twin.macro";

const LogIn = () => {
  const featureFlags = useFeatureFlags();
  const { sessionQuery, loggedIn } = useSession();
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);

  const mutation = useMutation(createSession, {
    onSuccess: async (data) => {
      if (data?.session?.email) {
        try {
          FullStory.identify(data.session.userId as string, {
            email: data.session.email,
          });
        } catch {
          console.log("FullStory not initialized");
        }

        await sessionQuery.refetch();
        setError(null);
        navigate("/");
      } else {
        setError("Unable to log in.");
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
  });

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    mutation.mutate({ email, password });
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn, navigate]);

  return (
    <>
      <AuthBoxCenterText>
        Enter your email address and password to log in.
      </AuthBoxCenterText>
      {error && <Destructive>{error}</Destructive>}
      <LoginForm onSubmit={onSubmit} loading={mutation.isLoading} />
      <AuthBoxCenterText tw="opacity-50 text-xs">
        <AuthBoxLink to="/forgot_password">Forgot your password?</AuthBoxLink>
      </AuthBoxCenterText>
      {featureFlags.signupBilling && (
        <>
          <hr className="opacity-20" />
          <AuthBoxCenterText>
            <Button asChild>
              <Link to="/sign-up" className="w-full">
                Sign up
              </Link>
            </Button>
          </AuthBoxCenterText>
        </>
      )}
    </>
  );
};

export default LogIn;
