import { InfoCircledIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";

const SearchTooltip = () => (
  <TooltipProvider delayDuration={0}>
    <Tooltip>
      <TooltipTrigger>
        <InfoCircledIcon className="ml-2 h-4 w-4 text-muted-foreground" />
      </TooltipTrigger>
      <TooltipContent className="mr-4 p-4">
        <div className="mb-2 font-bold">Search syntax</div>
        <ul className="mb-0 pb-0">
          <li>
            <span className="code">AND</span> and{" "}
            <span className="code">OR</span> can join words or phrases
          </li>
          <li>
            <span className="code">NOT</span> negates a single word or phrase
          </li>
          <li>
            <span className="code">"</span> wraps a number of tokens to signify
            a phrase for searching
          </li>
          <li>
            <span className="code">(</span> and
            <span className="code">)</span> signify precedence
          </li>
        </ul>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);
export default SearchTooltip;
