import React from "react";
import logoImage from "../../public/images/Qumis_FullColor_Logomark_RGB.png";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";

interface WelcomePopupProps {
  open: boolean;
  onClose: () => void;
}

const WelcomePopup: React.FC<WelcomePopupProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <div className="text-center">
          <img
            src={logoImage}
            alt="Qumis Logo"
            width={100}
            height={100}
            className="mx-auto mb-4"
          />
          <h2 className="text-2xl font-bold">Welcome to Qumis</h2>
          <p className="mt-2 text-lg">As you use Qumis, please remember:</p>
        </div>
        <div className="grid gap-6 py-3">
          <div className="rounded-lg border p-4">
            <h3 className="text-xl font-semibold">
              Qumis may not always be right
            </h3>
            <p className="mt-2 text-sm leading-relaxed text-gray-600">
              Qumis may give inaccurate responses. When in doubt, use the Qumis
              Clerk chat to ask additional questions and use external sources to
              verify responses.
            </p>
          </div>
          <div className="rounded-lg border p-4">
            <h3 className="text-xl font-semibold">
              Qumis will get better with your feedback
            </h3>
            <p className="mt-2 text-sm leading-relaxed text-gray-600">
              Please rate responses in the chat, particularly the rare instances
              where responses might be incorrect.
            </p>
          </div>
        </div>
        <div className="mt-6 text-right">
          <Button
            onClick={onClose}
            className="transition-opacity duration-200 hover:opacity-70"
          >
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomePopup;
