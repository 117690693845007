import { apiFn } from "~/api/utils";

export interface InviteData {
  id: string;
  organization: {
    name: string;
  };
  email: string;
  claimedAt: string | null;
  createdAt: string;
}

export const getInvites = apiFn<void, { invites: InviteData[] }>(
  "GET",
  "/invites"
);

export const getInvite = apiFn<{ id: string }, { invite: InviteData }>(
  "GET",
  (params) => `/invites/${params.id}`
);

export const createInvite = apiFn<
  { email: string; organization_id?: string },
  { invite: InviteData }
>("POST", "/invites");

export const destroyInvite = apiFn<{ id: string }, void>(
  "DELETE",
  (params) => `/invites/${params.id}`
);
