import { FormEvent, useState } from "react";
import { Label } from "~/components/ui/label";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import { AuthInput } from "~/components/AuthBox";

interface LoginFormProps {
  loading: boolean;
  onSubmit: (data: { email: string; password: string }) => void;
}

const LoginForm = ({ loading, onSubmit }: LoginFormProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ email, password });
  };

  return (
    <div className="grid gap-6">
      <form onSubmit={handleSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <AuthInput
              id="email"
              name="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={loading}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="password">
              Password
            </Label>
            <AuthInput
              id="password"
              name="password"
              type="password"
              disabled={loading}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <ButtonWithLoader loading={loading}>Sign In</ButtonWithLoader>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
