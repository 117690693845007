import { Navigate } from "react-router-dom";
import useSession from "~/hooks/useSession";

const Root = () => {
  const { loggedIn } = useSession();

  return loggedIn ? <Navigate to="/home" /> : <Navigate to="/log_in" />;
};

export default Root;
