import useSession from "~/hooks/useSession";
import { useQuery } from "react-query";
import { getSubscription } from "~/api";
import tw from "twin.macro";
import styled from "styled-components";
import { ManageBillingLink } from "~/components/ManageBillingButton";

const StyledBanner = styled.div(
  tw`bg-destructive py-2 text-center text-text-on-dark flex flex-row items-center justify-center`
);

const BillingBanner = () => {
  const { loggedIn } = useSession();
  const { data: subscriptionData, isLoading } = useQuery({
    queryKey: ["subscription"],
    queryFn: () => getSubscription(),
    enabled: !!loggedIn,
  });

  if (isLoading) {
    return null;
  }

  const billingStatus = subscriptionData?.subscription.billingStatus;
  switch (billingStatus) {
    case "payment_action_required":
    case "payment_failed":
      return (
        <StyledBanner>
          Something went wrong with your latest payment.
          <ManageBillingLink />
        </StyledBanner>
      );
    case "overdue":
      return (
        <StyledBanner>
          You have an invoice overdue.
          <ManageBillingLink />
        </StyledBanner>
      );
    default:
      return null;
  }
};

export default BillingBanner;
