import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { useState } from "react";

interface EmailReportDisclaimerProps {
  locked: boolean;
  setContent: (content: string) => void;
  setIsDisclaimerOpen: (isOpen: boolean) => void;
  onClose: () => void;
  unlock: (email: string) => void;
  warningText: string;
  isDisclaimerOpen: boolean;
  content: string;
  processingStatus: string;
}

export function EmailReportDisclaimer({
  setContent,
  content,
  locked,
  isDisclaimerOpen,
  setIsDisclaimerOpen,
  onClose,
  unlock,
  warningText,
  processingStatus,
}: EmailReportDisclaimerProps) {
  const renderContent = () => {
    if (processingStatus === "exempt") {
      return <ExemptContent gotoNext={() => setIsDisclaimerOpen(false)} />;
    }
    switch (content) {
      case "DifferentialContent":
        return (
          <DifferentialContent
            gotoNext={() => {
              if (!locked) {
                setIsDisclaimerOpen(false);
              }
              setContent("DifferentialContent");
            }}
          />
        );
      case "RenderSecurityContent":
        return (
          <RenderSecurityContent
            gotoNext={() => setContent("DifferentialContent")}
          />
        );
      case "AccessReportContent":
      default:
        return (
          <AccessReportContent
            warningText={warningText}
            unlock={unlock}
            onClose={onClose}
          />
        );
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Dialog defaultOpen={true} open={isDisclaimerOpen} onOpenChange={onClose}>
        {renderContent()}
      </Dialog>
    </div>
  );
}

interface renderDialogContentProps {
  onClose: () => void;
  unlock: (email: string) => void;
  warningText: string;
}

const AccessReportContent = ({
  warningText,
  unlock,
}: renderDialogContentProps) => {
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <DialogContent className="sm:max-w-[725px]">
      <DialogHeader>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span style={{ color: "red", fontSize: "24px" }}> 🔒</span>
              <span>Access Report</span>
            </div>
            <img
              src="/images/beta-100.png"
              alt="Qumis"
              className="my-1 h-20 py-2"
            />
          </div>
        </DialogTitle>
        <DialogDescription>
          <strong>Please enter the email used to request the report.</strong>
        </DialogDescription>
      </DialogHeader>
      <div className="grid gap-6 py-6">
        <div className=" grid-cols-3 items-center gap-6">
          <Label htmlFor="email" className="text-right">
            Email:
          </Label>
          <Input
            id="email"
            placeholder="Please enter your work email."
            className="unmask col-span-1 mt-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            data-fullstory-unmask
          />
          {warningText && <p className="mt-2 text-red-500">{warningText}</p>}
        </div>
        <div className="grid gap-6 py-6">
          <div className="grid grid-cols-3 items-center gap-6">
            <div className="col-span-3 mt-5 space-y-4">
              <p className="w-full p-4 text-sm shadow-sm ">
                {" "}
                ℹ️ We're currently testing this feature in BETA. If you're
                interested in accessing the full version, or if you have any
                feature requests or issues, please reach out to us at
                support@qumis.ai.
              </p>
              <p className="w-full p-4 text-sm shadow-sm ">
                {" "}
                ⚖️ The information and analysis provided on this website does
                not, and is not intended to, constitute legal advice. Please
                contact an attorney to obtain advice with respect to any
                particular legal matter.
              </p>
              <div className="mt-10 flex items-center justify-end">
                <label htmlFor="terms" className="ml-2">
                  Accept The{" "}
                  <a
                    href="https://www.qumis.ai/legal/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "black", textDecoration: "underline" }}
                  >
                    Terms & Conditions
                  </a>
                </label>
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="ml-4"
                  style={{ transform: "scale(1.5)", accentColor: "black" }}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button
          key={`${email}-${termsAccepted}`}
          type="submit"
          className="mt-5 border-black text-black transition-colors duration-300 hover:border-black hover:bg-black hover:text-white"
          variant="outline"
          onClick={() => {
            if (email && termsAccepted) {
              unlock(email);
            }
          }}
          disabled={!email || !termsAccepted}
        >
          Unlock Report
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

const RenderSecurityContent = ({ gotoNext }: { gotoNext: () => void }) => {
  return (
    <DialogContent className="sm:max-w-[725px]">
      <div className="flex h-full flex-col items-center justify-center">
        <span style={{ fontSize: "50px" }}>🔒</span>
        <h3 className="text-center text-2xl font-bold">Security Information</h3>
      </div>
      <div className="grid gap-6 py-6">
        <div className="grid grid-cols-3 items-center gap-6">
          <div className="col-span-3 mt-5 space-y-4">
            <p className="w-full p-4 shadow-sm ">
              🔐 All information is encrypted and securely stored.
            </p>
            <p className="w-full p-4 shadow-sm ">
              🚫 Your data is isolated and not used for model training.
            </p>
            <p className="w-full p-4 shadow-sm ">
              🗑️ This report and all documents uploaded will be automatically
              deleted in 24 hours. To re-access a report, please follow the
              steps to generate it again.
            </p>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button
          className="mt-5 border-black text-black transition-colors duration-300 hover:border-black hover:bg-black hover:text-white"
          variant="outline"
          onClick={() => gotoNext()}
        >
          Next
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

const DifferentialContent = ({ gotoNext }: { gotoNext: () => void }) => {
  return (
    <DialogContent className="sm:max-w-[725px]">
      <div className="flex h-full flex-col items-center justify-center">
        <img
          src="/images/cortex.png"
          alt="Qumis"
          className="my-1 mr-4 h-40 py-2"
        />
        <h3 className="text-center text-2xl font-bold">Qumis Difference</h3>
      </div>
      <div className="grid gap-6 py-6">
        <div className="grid grid-cols-3 items-center gap-6">
          <div className="col-span-3 mt-5 space-y-4">
            <p className="w-full p-4 shadow-sm ">
              📚 Our system is trained on thousands of insurance-related
              documents, including real coverage letters and policies pulled
              from the public court records.
            </p>
            <p className="w-full p-4 shadow-sm ">
              ⚖️ The output in training was verified by a team of attorneys to
              ensure the highest level of accuracy.
            </p>
            <p className="w-full p-4 shadow-sm ">
              🤖 In addition to large language models we use classic machine
              learning techniques including OCR, classifier models, and graph
              databases to generate high-quality outputs.
            </p>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button
          className="mt-5 border-black text-black transition-colors duration-300 hover:border-black hover:bg-black hover:text-white"
          variant="outline"
          onClick={() => gotoNext()}
        >
          View Report
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

const ExemptContent = ({ gotoNext }: { gotoNext: () => void }) => {
  return (
    <DialogContent className="sm:max-w-[725px]">
      <div className="flex h-full flex-col items-center justify-center">
        <img
          src="/images/logo.png"
          alt="Qumis"
          className="h-15 my-1 mr-4 py-4"
        />
        <h3 className="mt-10 text-center text-2xl font-bold">Example Demo</h3>
      </div>
      <div className="grid gap-6 py-6">
        <div className="grid grid-cols-3 items-center gap-6">
          <div className="col-span-3 mt-5 space-y-4">
            <p className="w-full p-4 shadow-sm ">
              🌟 This is an example demo to help you discover the power of
              Qumis, your AI assistant for analyzing insurance policies and
              extracting key information for claims, brokering, and compliance.
            </p>
            <p className="w-full p-4 shadow-sm ">
              📚 Try Qumis with your own documents and policies. Email your
              documents to our AI assistant at <strong>clerk@qumis.ai</strong>{" "}
              for analysis. Include any specific questions, such as comparing
              the attached policies and generating coverage reports.
            </p>
            <p className="w-full p-4 shadow-sm ">
              📥 You will receive an email with a link to your report shortly
              after submission.
            </p>
            <p className="w-full p-4 shadow-sm ">
              🗑️ For your security, all documents and reports are automatically
              deleted after 24 hours.
            </p>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button
          className="mt-5 border-black text-black transition-colors duration-300 hover:border-black hover:bg-black hover:text-white"
          variant="outline"
          onClick={() => gotoNext()}
        >
          View Report
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
