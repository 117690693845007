import { ShadowInnerIcon } from "@radix-ui/react-icons";
import { Button } from "~/components/ui/button";

interface ButtonWithLoaderProps extends React.ComponentProps<typeof Button> {
  disabled?: boolean;
  loading: boolean;
  children: React.ReactNode;
}

const ButtonWithLoader = ({
  disabled,
  loading,
  children,
  ...props
}: ButtonWithLoaderProps) => (
  <Button
    disabled={disabled || loading}
    className={`relative transition-all duration-200 ease-in-out ${
      loading ? "pl-8" : "pl-4"
    }`} // change padding as per your needs
    {...props}
  >
    <ShadowInnerIcon
      className={`absolute left-2 h-4 w-4 animate-spin transition-opacity ease-in-out ${
        loading ? "opacity-100" : "opacity-0"
      }`}
    />
    {children}
  </Button>
);

export default ButtonWithLoader;
