import { apiFn } from "~/api/utils";

export const getPasswordReset = apiFn<{ id: string }, { email: string }>(
  "GET",
  (params) => `/password_resets/${params.id}`
);

export const createPasswordReset = apiFn<
  { password_reset: { email: string } },
  Record<string, never>
>("POST", "/password_resets");

export const updatePassword = apiFn<
  { id: string; password_reset: { password: string } },
  { success: true }
>("PUT", (params) => `/password_resets/${params.id}`);
