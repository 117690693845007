import { QueryClient } from "react-query";
import { Label } from "~/components/ui/label";
import { Destructive } from "~/components/Alert";
import {
  Form,
  useActionData,
  redirect,
  useLoaderData,
  useNavigation,
  useParams,
} from "react-router-dom";
import { AuthBoxCenterText, AuthInput } from "~/components/AuthBox";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import { Input } from "~/components/ui/input";
import { getPasswordReset, updatePassword } from "~/api";
import "twin.macro";

export const loader = async ({ params }: { params: { id?: string } }) => {
  return await getPasswordReset({ id: params.id || "" });
};

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    try {
      await updatePassword({
        id: data.token as string,
        password_reset: {
          password: data.password as string,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        return {
          status: 400,
          error: e.message,
        };
      }
    }

    await queryClient.invalidateQueries({ queryKey: ["session"] });

    return redirect("/");
  };

const ResetPassword = () => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const { email } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { id } = useParams();
  const actionData = useActionData() as
    | undefined
    | Awaited<{
        status: number;
        error: string;
      }>;

  const error = actionData?.error;

  return (
    <>
      <AuthBoxCenterText>Choose a new password</AuthBoxCenterText>
      <p className=" text-text-on-dark opacity-50">{email}</p>
      {error && <Destructive>{error}</Destructive>}
      <Form method="post">
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label htmlFor="password" className="sr-only">
              Password
            </Label>
            <Input type="hidden" id="token" name="token" value={id} />
            <AuthInput
              id="password"
              name="password"
              type="password"
              disabled={isSubmitting}
            />
            <div className="mb-4 text-sm text-muted-foreground">
              Create a new password above. Must include at least 8 characters, 1
              number, 1 uppercase letter, 1 lowercase
            </div>
          </div>
          <ButtonWithLoader loading={isSubmitting}>
            Reset Password & Log In
          </ButtonWithLoader>
        </div>
      </Form>
    </>
  );
};

export default ResetPassword;
