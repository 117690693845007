import { NavLink as RouterNavLink } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";

const NavLink = styled(RouterNavLink)(() => [
  // Base styles
  tw`inline-flex items-center justify-center rounded-md text-sm font-medium`,
  tw`transition-colors focus-visible:outline-none focus-visible:ring-2`,
  // tw`focus-visible:ring-ring focus-visible:ring-offset-2`,

  // Default state
  tw`text-muted-foreground hover:text-black`,

  // Active state
  tw`aria-[current=page]:(text-black)`,

  // Sizing and spacing
  tw`h-10 px-4 py-2`,
]);

export default NavLink;
