import { CardDescription, CardHeader, CardTitle } from "~/components/ui/card";
import { DocumentType } from "~/api/query_fns/documents";
import { format } from "date-fns";
import ClaimTag from "./ClaimTag";

const DocumentCard = ({ doc }: { doc: DocumentType }) => {
  const dateObj = new Date(doc.createdAt);
  const formattedDate = format(dateObj, "MMM d, yyyy");

  return (
    <div className="custom-shadcn-result-card">
      <CardHeader>
        {doc.claimNumber && (
          <div className="mb-2">
            <ClaimTag color={doc.claimColor} number={doc.claimNumber} />
          </div>
        )}
        <CardTitle className="group-hover:underline">{doc.filename}</CardTitle>
        <CardDescription>
          {formattedDate} &bull; {doc.uploaderEmail}
        </CardDescription>
      </CardHeader>
    </div>
  );
};

export default DocumentCard;
