import { Navigate, Outlet } from "react-router-dom";
import useSession from "~/hooks/useSession";

const AuthenticatedRoutes = () => {
  const { loggedIn } = useSession();

  return loggedIn ? <Outlet /> : <Navigate to="/log_in" />;
};

export default AuthenticatedRoutes;
