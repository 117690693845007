import { apiFn } from "~/api/utils";

interface RequestParams {
  lexical?: string;
  semantic?: string;
  page?: number;
  per_page?: number;
  qumis_reference_docs?: boolean;
  org_reference_docs?: boolean;
  org_policies?: boolean;
}

export interface SearchResult {
  id: string;
  filename: string;
  documentType: "policy" | "reference";
  organizationName: string;
  uploaderEmail: string;
  claimNumber: string | null;
  claimColor: string | null;
  createdAt: string;
  matches: SearchResultMatch[];
}

export interface SearchResultMatch {
  type: "semantic" | "lexical";
  highlight: string;
  score: number;
  pageNumber: number;
}

export interface SearchResults {
  results: SearchResult[];
  total: number;
}

export const getSearch = apiFn<RequestParams, SearchResults>("GET", "/search");
