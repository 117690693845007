import { Worker } from "@react-pdf-viewer/core";
import { useQuery } from "react-query";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSearch, getDocument } from "~/api";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import SearchResultDocument from "~/routes/GenPrecedentSearch/SearchResultDocument";
import SearchResultsColumn from "~/routes/GenPrecedentSearch/SearchResultsColumn";
import useDebounce from "~/hooks/useDebounce";

interface LoaderParams {
  params: {
    id?: string;
  };
}

type GenPrecedentSearchLoaderResponse = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderParams) => {
  return await getDocument({ id: params.id || "", includes: "text" });
};

const getSelection = () => {
  if (window.getSelection) {
    return window.getSelection() || null;
  }

  if (document.getSelection) {
    return document.getSelection() || null;
  }
  return null;
};

const GenPrecedentSearch = () => {
  const { presignedUrl, document } =
    useLoaderData() as GenPrecedentSearchLoaderResponse;
  const [searchParams] = useSearchParams();
  const [highlighted, setHighlighted] = useState<null | string>(null);
  const [query, setQuery] = useState<string>("");
  const [orgPolicies, setOrgPolicies] = useState<boolean>(false);
  const [orgReferenceDocs, setOrgReferenceDocs] = useState<boolean>(true);
  const [qumisReferenceDocs, setQumisReferenceDocs] = useState<boolean>(true);
  const debouncedQuery = useDebounce(query, 500);
  const [activeSearchResultDoc, setActiveSearchResultDoc] = useState<{
    id: string;
    page: number;
    highlight?: string;
    matchType?: string;
  } | null>(null);
  const viewingActiveSearchResultDoc = !!activeSearchResultDoc;
  const [searchResultsPage, setSearchResultsPage] = useState<number>(1);

  const searchResults = useQuery({
    queryKey: [
      "search",
      highlighted,
      debouncedQuery,
      searchResultsPage,
      orgPolicies,
      orgReferenceDocs,
      qumisReferenceDocs,
    ],
    queryFn: () =>
      getSearch({
        lexical: debouncedQuery,
        semantic: highlighted as string,
        page: searchResultsPage,
        qumis_reference_docs: qumisReferenceDocs,
        org_reference_docs: orgReferenceDocs,
        org_policies: orgPolicies,
      }),
    enabled: !!highlighted || !!debouncedQuery,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const searchResultDocQuery = useQuery({
    queryKey: ["document", activeSearchResultDoc?.id],
    queryFn: () => getDocument({ id: activeSearchResultDoc?.id as string }),
    enabled: !!activeSearchResultDoc,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setSearchResultsPage(1);
  }, [debouncedQuery, highlighted]);

  const setHighlightedContent = () => {
    const highlightEvent = getSelection();
    const highlightedContent =
      highlightEvent?.toString()?.replace(/(\r\n|\n|\r)/gm, "") || null;
    setHighlighted(highlightedContent);
  };

  const navigateToMainDocument = () => {
    setActiveSearchResultDoc(null);
  };

  const isSearchResultDoc = !!searchResultDocQuery?.data?.document;

  const initialPage = searchParams.get("page");

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div className="w-fullp-8 flex h-full gap-12">
        <div className="h-[80vh]  flex-grow overflow-y-auto">
          <Heading as="h2" className="custom-shadcn-components">
            {isSearchResultDoc
              ? searchResultDocQuery?.data?.document.filename
              : document.filename}
          </Heading>
          {isSearchResultDoc && (
            <Button
              variant="link"
              className="p-0 text-left text-base font-normal text-inherit"
              onClick={navigateToMainDocument}
            >
              {document.filename.length < 50
                ? document.filename
                : `${document.filename.substring(
                    0,
                    30
                  )}...${document.filename.substr(
                    document.filename.length - 30
                  )}`}
            </Button>
          )}

          <MainDocument
            presignedUrl={presignedUrl}
            persistHighlight={setHighlightedContent}
            hideDocument={viewingActiveSearchResultDoc}
            highlightedContent={activeSearchResultDoc ? "" : highlighted}
            initialPage={initialPage ? parseInt(initialPage, 10) : undefined}
          />
        </div>

        <div className="flex flex-col">
          <SearchResultsColumn
            query={query}
            setQuery={setQuery}
            searchResults={searchResults}
            setActiveSearchResultDoc={setActiveSearchResultDoc}
            page={searchResultsPage}
            setPage={setSearchResultsPage}
            orgPolicies={orgPolicies}
            setOrgPolicies={setOrgPolicies}
            orgReferenceDocs={orgReferenceDocs}
            setOrgReferenceDocs={setOrgReferenceDocs}
            qumisReferenceDocs={qumisReferenceDocs}
            setQumisReferenceDocs={setQumisReferenceDocs}
          />
          {activeSearchResultDoc && (
            <SearchResultDocument
              initialPage={activeSearchResultDoc.page}
              documentQuery={searchResultDocQuery}
              highlightedContent={
                activeSearchResultDoc.highlight?.substring(0, 40) || ""
              }
              matchType={activeSearchResultDoc.matchType || ""}
            />
          )}
        </div>
      </div>
    </Worker>
  );
};

export default GenPrecedentSearch;
