/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import { Card } from "~/components/ui/card";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";
import { ReportType } from "~/api/query_fns/coverage-analysis";

export default function UCCoverageCheckListNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
  } = useGenerateReportNew("coverage_checklist" as ReportType);

  if (isLoading) {
    return <LoadingComponent title="GENERATING COVERAGE ANALYSIS" />;
  }

  return (
    <StepReportComponent
      title="Coverage Checklist"
      lastStepName="Generate Coverage Checklist"
      steps={[
        {
          number: 1,
          label: "Policy Documents",
          description: "Upload a single policy document.",
          component: (
            <PolicyDocumentsComponent
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 2,
          label: "Spec Sheet",
          description:
            "Upload a Spec Sheet/Checklist document in Excel Format. Contains a list of specs, for examples coverages, which must be checked against the policy. Must be in Excel format.",
          component: (
            <SpecSheetComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport} // Pass the final step handler
      onCancelPath="/coverage-checklist"
    />
  );
}

const PolicyDocumentsComponent = ({
  handleUploadComplete,
}: {
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step1" className="flex w-full flex-col">
      <Card className="p-3 ">
        <UploadArea
          db_document_type="policy"
          onUploadComplete={handleUploadComplete}
        />
      </Card>
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
      placeholder="Please describe in detail which columns need to be filled in the Spec Sheet."
    />
  );
};

const SpecSheetComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step2" className="flex w-full flex-col">
      <Card className=" p-3">
        <UploadArea
          db_document_type="spec"
          onUploadComplete={handleUploadComplete}
          description="Upload a single spec sheet in excel format."
          allowedFileFormats={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx", ".XLSX"],
          }}
        />
        <AdditionalInformationComponent
          additionalNotes={additionalNotes}
          setAdditionalNotes={setAdditionalNotes}
          handleUploadComplete={handleUploadComplete}
        />
      </Card>
    </div>
  );
};
