import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const defaultBaseUrl = import.meta.env.VITE_API_URL;

interface AxiosError {
  response?: AxiosResponse<{
    error: string;
  }>;
}

export const request = async <T>(
  config: AxiosRequestConfig,
  baseUrl: string,
  withCredentials = true
): Promise<T> => {
  try {
    const response = await axios({
      ...config,
      baseURL: baseUrl,
      withCredentials: withCredentials,
      headers: {
        "Content-Type": "application/json",
        ...config.headers,
      },
    });

    return response.data as T;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw new Error(axiosError.response?.data?.error || "API request error");
  }
};

export type PathResolver<RequestParams> = RequestParams extends void
  ? () => string
  : (params: RequestParams) => string;

export const apiFn =
  <RequestParams = void, ResponseData = unknown>(
    method: AxiosRequestConfig["method"],
    path: string | PathResolver<RequestParams>,
    providedBaseUrl?: string,
    withCredentials?: boolean
  ) =>
  async (params?: RequestParams): Promise<ResponseData> => {
    const resolvedUrl =
      typeof path === "function" ? path(params as RequestParams) : path;

    const config: AxiosRequestConfig = {
      method,
      url: resolvedUrl,
    };

    if (method === "GET" || method === "DELETE") {
      config.params = params;
    } else {
      config.data = params;
    }

    console.log("config", config);
    const data = await request<ResponseData>(
      config,
      providedBaseUrl || defaultBaseUrl,
      withCredentials
    );

    return data;
  };
