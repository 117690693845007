import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import * as FullStory from "@fullstory/browser";
import { Toaster } from "react-hot-toast";

import "react-toastify/dist/ReactToastify.css";

import { GlobalStyles } from "twin.macro";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "./index.css"; // importing our .css file is needed to override react-pdf-viewer styles
import {
  SinglePageLayout,
  DefaultLayout,
  BubblesLayout,
  GenLayout,
} from "~/components/Layout";
import ErrorBoundary from "~/components/ErrorBoundary";
import AuthenticatedRoutes from "~/routes/AuthenticatedRoutes";
import { SessionProvider } from "~/hooks/useSession";
import ProtectedRoutes from "~/routes/ProtectedRoutes";

import Root from "~/routes/Root";
import UCPrecedentSearch, {
  action as analyzeAction,
} from "~/routes/UCPrecedentSearch";
import { action as analyzeChatAction } from "~/routes/UCPolicyChat";
import GenPrecedentSearch, {
  loader as genPrecedentSearchLoader,
} from "~/routes/GenPrecedentSearch";
import { loader as genChatPolicyLoader } from "~/routes/GenPolicyChat";
import DocumentsShow, {
  loader as documentsShowLoader,
} from "~/routes/DocumentsShow";
import LogIn from "~/routes/LogIn";
import SignUp, { action as signUpAction } from "~/routes/SignUp";
import SignUpPlan from "~/routes/SignUpPlan";
import {
  default as AdminMembers,
  loader as adminMembersLoader,
  action as adminMembersAction,
} from "~/routes/Admin/Members";
import {
  default as AdminOrganizations,
  action as adminOrganizationsAction,
  loader as adminOrganizationsLoader,
} from "~/routes/Admin/Organizations";
import {
  default as AdminDeleteInvite,
  action as adminDeleteInviteAction,
} from "~/routes/Admin/Members/DeleteInvite";
import AcceptInvite, {
  loader as acceptInviteLoader,
  action as acceptInviteAction,
} from "~/routes/AcceptInvite";
import Documents from "~/routes/Documents";
import Search, { loader as searchLoader } from "~/routes/Search";
import ForgotPassword, {
  action as forgotPasswordAction,
} from "~/routes/ForgotPassword";
import ResetPassword, {
  action as resetPasswordAction,
  loader as resetPasswordLoader,
} from "~/routes/ResetPassword";
import AccountSettings, {
  action as accountSettingsAction,
  loader as accountSettingsLoader,
} from "~/routes/AccountSettings";
import { action as createInviteAction } from "~/routes/AdminQumisInviteUsers";

import { CopilotKit } from "@copilotkit/react-core";
import "~/../public/copilot-css/styles.css";
import UCReportSelection from "./routes/UCReportSelection";

import ReportPrivate, {
  reportPrivateAnalysisLoader,
} from "./routes/GenReportPrivate";
import GenReportPublic, {
  loader as fetchJobReportLoader,
} from "./routes/GenReportPublic";
import UnAuthenticatedRoutes from "./routes/UnAuthenticatedRoutes";
import Version from "./routes/Version";
import ErrorBoundaryReport from "./components/ErrorBoundaryReport";
import Hotjar from "@hotjar/browser";
import LandingPage from "./routes/LandingPage";
import UCPolicyChat from "./routes/UCPolicyChat";
import GenPolicyChat from "./routes/GenPolicyChat";
import UCClaimCoverageNew from "./routes/UCClaimCoverageNew";
import UCPolicyComparisonNew from "./routes/UCPolicyComparisionNew";
import UCGenericReportNew from "./routes/UCGenericReportNew";
import TestComponent from "./routes/TestComponent";
import UCGenericReportV2 from "./routes/UCGenericReportV2";
import UCPolicyComparisonV2 from "./routes/UCPolicyComparisonV2";
import UCClaimCoverageV2 from "./routes/UCClaimCoverageV2";
import Security from "./routes/Security";
import AdminQumisInviteUsers from "./routes/AdminQumisInviteUsers";
import "react-toastify/dist/ReactToastify.css";
import UCTowerAnalysisNew from "./routes/UCTowerAnalysisNew";
import UCCoverageCheckListNew from "./routes/UCCoverageCheckListNew";
import UCCoverageCheckListV2 from "./routes/UCCoverageCheckListV2";

const siteId = 4966068;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const queryClient = new QueryClient();

const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const fullstoryOrgID = import.meta.env.VITE_FULLSTORY_ORG_ID;

if (sentryDSN && sentryDSN !== "") {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/api\.qumis\.ai/],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (fullstoryOrgID && fullstoryOrgID !== "") {
  FullStory.init({ orgId: fullstoryOrgID });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route path="/" element={<Root />} />

      <Route element={<BubblesLayout />} errorElement={<ErrorBoundary />}>
        <Route path="/log_in" element={<LogIn />} />

        <Route
          path="/sign-up"
          element={<SignUp />}
          action={signUpAction(queryClient)}
        />
        <Route element={<AuthenticatedRoutes />}>
          <Route path="/sign-up-plan" element={<SignUpPlan />} />
        </Route>
        <Route
          path="/forgot_password"
          element={<ForgotPassword />}
          action={forgotPasswordAction}
        />
        <Route
          path="/invites/:id"
          element={<AcceptInvite />}
          action={acceptInviteAction(queryClient)}
          loader={acceptInviteLoader}
        />
        <Route
          path="/reset_password/:id"
          element={<ResetPassword />}
          action={resetPasswordAction(queryClient)}
          loader={resetPasswordLoader}
        />
      </Route>

      <Route element={<GenLayout />} errorElement={<ErrorBoundary />}>
        <Route errorElement={<ErrorBoundaryReport />}>
          <Route element={<UnAuthenticatedRoutes />}>
            <Route
              path="/report/:jobId"
              element={<GenReportPublic />}
              loader={fetchJobReportLoader}
            />
            <Route path="/version" element={<Version />} />
          </Route>
          <Route element={<AuthenticatedRoutes />}>
            <Route
              path="/analyze/:id"
              element={<GenPrecedentSearch />}
              loader={genPrecedentSearchLoader}
            />

            <Route
              path="report/analysis/:job_id"
              loader={reportPrivateAnalysisLoader}
              element={<ReportPrivate />}
            >
              <Route
                path="chat/:chat_message_id?"
                loader={reportPrivateAnalysisLoader}
                element={<ReportPrivate />}
              />
            </Route>

            <Route
              path="/chat/:id"
              element={<GenPolicyChat />}
              loader={genChatPolicyLoader}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<SinglePageLayout />} errorElement={<ErrorBoundary />}>
        <Route errorElement={<ErrorBoundary />}>
          <Route element={<AuthenticatedRoutes />}>
            <Route
              path="/documents/:id"
              element={<DocumentsShow />}
              loader={documentsShowLoader}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<DefaultLayout />} errorElement={<ErrorBoundary />}>
        <Route errorElement={<ErrorBoundary />}>
          <Route element={<AuthenticatedRoutes />}>
            <Route
              path="/analyze"
              element={<UCPrecedentSearch />}
              action={analyzeAction}
            />
            <Route
              path="/chat"
              element={<UCPolicyChat />}
              action={analyzeChatAction}
            />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/documents" element={<Documents />} />
            <Route
              path="/documents/search"
              loader={searchLoader}
              element={<Search />}
            />
            <Route
              path="/account_settings"
              element={<AccountSettings />}
              action={accountSettingsAction(queryClient)}
              loader={accountSettingsLoader}
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes roles={["site_admin", "owner", "admin"]} />
            }
          >
            <Route
              path="/admin/site"
              action={createInviteAction}
              element={<AdminQumisInviteUsers />}
            />
            <Route
              path="/admin/members"
              loader={adminMembersLoader}
              action={adminMembersAction}
              element={<AdminMembers />}
            />
            <Route
              path="/admin/invites/:id/delete"
              action={adminDeleteInviteAction}
              element={<AdminDeleteInvite />}
            />
          </Route>
          <Route element={<ProtectedRoutes roles={["site_admin"]} />}>
            <Route
              path="/admin/organizations"
              loader={adminOrganizationsLoader}
              action={adminOrganizationsAction}
              element={<AdminOrganizations />}
            />
          </Route>
          <Route path="/reports" element={<UCReportSelection />} />
          <Route
            path="/claim-coverage"
            element={
              <UCClaimCoverageV2
                reportTitle="Coverage Reports"
                reportType="policy_fact"
                newPath="/claim-coverage-new"
              />
            }
          />
          <Route
            path="/coverage-checklist-new"
            element={<UCCoverageCheckListNew />}
          />
          <Route path="/claim-coverage-new" element={<UCClaimCoverageNew />} />
          <Route path="/tower-analysis-new" element={<UCTowerAnalysisNew />} />
          <Route
            path="/policy-comparison"
            element={
              <UCPolicyComparisonV2
                reportTitle="Comparison Reports"
                reportType="policies_compare"
                newPath="/policy-comparison-new"
              />
            }
          />
          <Route
            path="/policy-comparison-new"
            element={<UCPolicyComparisonNew />}
          />
          <Route path="/coverage/analysis" element={<ReportPrivate />} />
          <Route
            path="/coverage-checklist"
            element={
              <UCCoverageCheckListV2
                reportTitle="Coverage Checklist"
                reportType="coverage_checklist"
                newPath="/coverage-checklist-new"
              />
            }
          />
          <Route
            path="/generic-report"
            element={
              <UCGenericReportV2
                reportTitle="Custom Reports"
                reportType="generic_report"
                newPath="/generic-report-new"
              />
            }
          />
          <Route path="/generic-report-new" element={<UCGenericReportNew />} />
          <Route path="/test" element={<TestComponent />} />
          <Route element={<UnAuthenticatedRoutes />}>
            <Route path="/security" element={<Security />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <GlobalStyles />
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <Toaster position="top-center" reverseOrder={false} />
        <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
          <RouterProvider router={router} />
        </CopilotKit>
      </SessionProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
