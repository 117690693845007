import tw, { TwStyle } from "twin.macro";
import styled from "styled-components";

const badgeVariants: Record<string, TwStyle> = {
  primary: tw`bg-primary`,
  destructive: tw`bg-destructive`,
};

interface ContainerProps {
  variant?: keyof typeof badgeVariants;
}

const Badge = styled.span<ContainerProps>(() => [
  tw`rounded-sm px-2 py-1 text-xs font-semibold text-black`,
  ({ variant = "primary" }) => badgeVariants[variant],
]);

export { Badge, badgeVariants };
