import React from "react";
import { Button } from "~/components/ui/button";
import { useNavigate } from "react-router-dom";

interface ReportGenerationButtonProps {
  buttonText: string;
  route: string;
}

const UCReportGenerationButtonComponent: React.FC<
  ReportGenerationButtonProps
> = ({ buttonText, route }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Button
        onClick={handleClick}
        variant="default"
        size="sm"
        className="min-h-[40px] min-w-[420px] bg-black text-white hover:bg-gray-800"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default UCReportGenerationButtonComponent;
