import "twin.macro";

export const ColorRadioInput: React.FC<{
  value: string;
  name: string;
  label: string;
}> = ({ label, name, value }) => (
  <input
    type="radio"
    name={name}
    id={label}
    aria-label={label}
    value={value}
    style={
      {
        "--local-input-color": value,
      } as React.CSSProperties
    }
    tw="mr-4 h-4 appearance-none rounded-full border-4 border-[var(--local-input-color)] bg-[var(--local-input-color)] p-2.5 checked:(border-white outline outline-2 outline-[var(--local-input-color)])"
  />
);

export default ColorRadioInput;
