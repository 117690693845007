import { QueryClient } from "react-query";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { getMe, updateMe } from "~/api";

import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import Alert, { Destructive } from "~/components/Alert";
import { Heading } from "~/components/ui/heading";

type LoaderResponse = Awaited<ReturnType<typeof loader>>;

export const loader = async () => {
  return await getMe();
};

type ActionResponse = Awaited<ReturnType<typeof action>>;

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    const userUpdateData: {
      email?: string;
      password?: string;
      current_password?: string;
    } = {
      email: data.email as string,
    };

    if (data.password && String(data.password).trim() !== "") {
      userUpdateData.password = data.password as string;
    }

    if (data.current_password && String(data.current_password).trim() !== "") {
      userUpdateData.current_password = data.current_password as string;
    }

    try {
      const response = await updateMe({
        user: userUpdateData,
      });

      await queryClient.invalidateQueries({ queryKey: ["session"] });

      return response;
    } catch (e) {
      if (e instanceof Error) {
        return {
          status: 400,
          error: e.message,
        };
      }
    }
  };

const AccountSettings = () => {
  const { user } = useLoaderData() as LoaderResponse;
  const actionData = useActionData() as undefined | ActionResponse;
  const navigation = useNavigation();

  const isSubmitting = navigation.state === "submitting";
  const error =
    actionData && "error" in actionData ? (actionData.error as string) : null;

  return (
    <div className="custom-shadcn-components flex min-h-[75vh]  justify-center">
      <div className="mt-10 w-full max-w-[60vw]">
        <div className="mb-4">
          <Heading as="h2" className="text-black ">
            Account settings
          </Heading>
        </div>
        {error ? (
          <Destructive>{error}</Destructive>
        ) : actionData ? (
          <Alert title="Success">Account successfully updated</Alert>
        ) : (
          <></>
        )}
        <Form method="post" className="mt-8  p-2">
          <div className="grid gap-6">
            <div className="grid gap-2">
              <Label htmlFor="email" className="text-black">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                defaultValue={user.email}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="current_password" className="text-black">
                Current password
              </Label>
              <Input
                id="current_password"
                name="current_password"
                type="password"
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password" className="text-black">
                New Password
              </Label>
              <Input id="password" name="password" type="password" />
            </div>

            <div className="flex justify-center">
              <ButtonWithLoader loading={isSubmitting}>
                Update Password
              </ButtonWithLoader>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AccountSettings;
