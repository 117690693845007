import { apiFn } from "~/api/utils";

export interface VotePromptTemplateResponse {
  id: string;
  user_id: string;
  prompt_template_id: string;
  vote: number;
  created_at: string;
  updated_at: string;
}

export const postVotePromptTemplate = apiFn<
  { prompt_template_id: string; vote: number },
  VotePromptTemplateResponse
>("POST", () => `/prompt_templates/prompt_votes`);
