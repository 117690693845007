import { apiFn } from "~/api/utils";

export type PromptGroupType =
  | "my_reports"
  | "organization_reports"
  | "qumis_reports";

export type PromptTemplate = {
  id: string;
  name: string;
  description: string;
  shared: boolean;
  promptGroup: string;
  isOwnedByCurrentUser: boolean;
  vote: number;
  upvotes: number;
  email?: string;
};

export type PromptTemplateGroup = {
  group: string;
  options: PromptTemplate[];
};

export interface PostPromptTemplateParams {
  id?: string;
  name: string;
  description: string;
  shared: boolean;
  email?: string;
  is_owned_by_current_user: boolean;
  prompt_group: PromptGroupType;
}

export interface PostPromptTemplateResponse {
  id: string;
  name: string;
  description: string;
  shared: boolean;
  email?: string;
  is_owned_by_current_user: boolean;
  prompt_group: PromptGroupType;
  created_at: string;
  updated_at: string;
}

export const getPromptTemplate = apiFn<
  { id: string },
  PostPromptTemplateResponse
>("GET", (params) => `/prompt_templates/${params.id}`);

export const getPromptTemplates = apiFn<void, PromptTemplateGroup[]>(
  "GET",
  "/prompt_templates"
);

export const postPromptTemplate = apiFn<
  PostPromptTemplateParams,
  PostPromptTemplateResponse
>("POST", "/prompt_templates");

export const putPromptTemplate = apiFn<
  PostPromptTemplateParams,
  PostPromptTemplateResponse
>("PUT", (params) => `/prompt_templates/${params.id}`);

export const destroyPromptTemplate = apiFn<{ id: string }, void>(
  "DELETE",
  (params) => `/prompt_templates/${params.id}`
);
