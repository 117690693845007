import { Label } from "~/components/ui/label";
import { Form, useActionData, useNavigation } from "react-router-dom";
import {
  AuthBoxCenterText,
  AuthInput,
  AuthBoxLink,
} from "~/components/AuthBox";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import { createPasswordReset } from "~/api";
import "twin.macro";

export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);

  await createPasswordReset({
    password_reset: { email: data.email as string },
  });

  return { success: true };
};

const ForgotPassword = () => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const actionData = useActionData() as undefined | Awaited<{ success: true }>;

  if (actionData?.success) {
    return (
      <>
        <AuthBoxCenterText>
          Please check your email for the link to reset your password
        </AuthBoxCenterText>
        <AuthBoxCenterText>It will expire in 24 hours</AuthBoxCenterText>
      </>
    );
  }

  return (
    <>
      <AuthBoxCenterText>
        Enter your email address to reset your password.
      </AuthBoxCenterText>
      <Form method="post">
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label htmlFor="email" className="sr-only">
              Email
            </Label>
            <AuthInput id="email" name="email" type="email" />
          </div>
          <ButtonWithLoader loading={isSubmitting}>
            Reset Password
          </ButtonWithLoader>
        </div>
      </Form>
      <AuthBoxCenterText tw="opacity-50 text-xs">
        <AuthBoxLink to="/">Back to log in</AuthBoxLink>
      </AuthBoxCenterText>
    </>
  );
};

export default ForgotPassword;
