import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Input } from "~/components/ui/input";

import SearchTooltip from "./SearchTooltip";

interface SearchBoxProps {
  query: string;
  setQuery: (query: string) => void;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  query,
  setQuery,
  ...props
}) => (
  <div className="flex w-full items-center">
    <div className="flex-grow">
      <div className="relative">
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500" />
        <Input
          className="pl-11 pr-8"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          {...props}
        />
        <div className="absolute right-3 top-[11px] text-gray-500">
          <SearchTooltip />
        </div>
      </div>
    </div>
  </div>
);

export default SearchBox;
