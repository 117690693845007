import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";

export default function UCTowerAnalysisNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
  } = useGenerateReportNew("tower_analysis");

  if (isLoading) {
    return <LoadingComponent title="GENERATING TOWER ANALYSIS" />;
  }

  return (
    <StepReportComponent
      title="Generate Tower Analysis"
      lastStepName="Generate Tower Analysis"
      steps={[
        {
          number: 1,
          label: "Tower Documents",
          description:
            "Upload the tower documents you would like to analyze here. Please drag and drop them from your computer. This tool will analyze the contents of the tower documents you upload. For best results, please upload complete and relevant documents.",
          component: (
            <TowerDocumentsComponent
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 2,
          label: "Additional Information",
          description:
            "You can provide additional information or context here for the analysis. Please note that this text box will not change the format of this report. If you would like to generate a custom report with your own instructions or formatting, please use the Custom Report tool from the home screen.",
          component: (
            <AdditionalInformationComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/tower-analysis"
    />
  );
}

const TowerDocumentsComponent = ({
  handleUploadComplete,
}: {
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step1" className="flex w-full flex-col">
      <UploadArea
        db_document_type="policy"
        onUploadComplete={handleUploadComplete}
      />
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
    />
  );
};
