import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface DocViewerProps {
  fileUrl: string;
}

const CustomDocViewer: React.FC<DocViewerProps> = ({ fileUrl }) => {
  const docs = [{ uri: fileUrl }];

  return (
    <div style={{ minHeight: "500px" }}>
      <DocViewer
        config={{ header: { disableHeader: true } }}
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ width: "100%", height: "100vh" }}
      />
    </div>
  );
};

export default CustomDocViewer;
