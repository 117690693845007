import { useState } from "react";
import { useQuery } from "react-query";

import { Link } from "react-router-dom";
import {
  ReportJob,
  ReportType,
  getReportsForUser,
} from "~/api/query_fns/coverage-analysis";
import Pagination from "~/components/Pagination";
import { Heading } from "~/components/ui/heading";
import { Paragraph } from "~/components/ui/paragraph";
import { CardHeader, CardTitle } from "~/components/ui/card";

import { format } from "date-fns";
import { Badge } from "~/components/ui/badge";

interface ExistingReportsProps {
  report_types: ReportType[];
  report_title: string;
}

const ExistingReports: React.FC<ExistingReportsProps> = ({
  report_types,
  report_title,
}) => {
  const [page, setPage] = useState<number>(1);

  const reportsQuery = useQuery({
    queryKey: ["policyDocuments", page],
    queryFn: () => getReportsForUser({ page: page, report_types }),
    refetchOnWindowFocus: false,
  });

  const hasReports =
    reportsQuery.data?.jobs && reportsQuery.data.jobs.length > 0;

  return (
    <div className="flex h-full flex-col">
      <Heading
        as="h2"
        className="custom-heading text-black-100 flex flex-row items-center gap-2"
      >
        {report_title}
      </Heading>
      {hasReports && (
        <Paragraph variant="small" className="mb-4 text-muted-foreground">
          Showing {Math.min(reportsQuery.data?.total ?? 0, 5)} of{" "}
          {reportsQuery.data?.total ?? 0}
        </Paragraph>
      )}

      <div className="flex h-full flex-grow items-center justify-center overflow-auto">
        {hasReports ? (
          <div className="h-full w-full">
            {reportsQuery.data?.jobs.map((report) => (
              <Link to={`/report/analysis/${report.jobId}`} key={report.jobId}>
                <ReportCard report={report} />
              </Link>
            ))}
          </div>
        ) : (
          <Paragraph className="text-black-100 text-center text-lg font-bold">
            No existing reports available.
          </Paragraph>
        )}
      </div>
      {hasReports && (
        <div className="mt-auto pt-4">
          <Pagination
            setPage={(p) => setPage(p)}
            total={reportsQuery.data?.total ?? 0}
            page={page}
            perPage={5}
          />
        </div>
      )}
    </div>
  );
};

export default ExistingReports;

const ReportCard = ({ report }: { report: ReportJob }) => {
  const dateObj = new Date(report.createdAt);
  const formattedDate = format(dateObj, "MMM d, yyyy");

  return (
    <div className="custom-shadcn-result-card ">
      <CardHeader>
        <CardTitle className="group-hover:underline">
          {report.reportName || "Report Name Empty"}
        </CardTitle>

        {formattedDate}

        <div className="flex flex-wrap overflow-x-auto">
          {[
            report.claimNumber && (
              <Badge
                key="claimNumber"
                className="m-1"
                variant="primary"
                style={{ backgroundColor: "#2660ff" }}
              >
                Claim Number: {report.claimNumber}
              </Badge>
            ),
            ...report.documents.map((rep) => (
              <Badge
                className="m-1"
                key={rep.id}
                variant="primary"
                style={{ backgroundColor: "darkgrey" }}
              >
                File: {rep.filename}
              </Badge>
            )),
          ].filter(Boolean)}
        </div>
      </CardHeader>
    </div>
  );
};
