import { apiFn } from "~/api/utils";
import { DocumentType } from "./documents";

export interface QueryExpansionChatResponse {
  improved_reasoning: string;
  improved_request: string;
}

export interface QueryExpansionChatParams {
  user_request: string;
  context: string;
}

export interface QueryExpansionReportResponse {
  report_interpreter_analysis: {
    improved_request: string;
  };
}

export interface QueryExpansionReportParams {
  user_request: string;
  documents: DocumentType[];
}

export const generateQueryExpansionsChatLLMService = apiFn<
  QueryExpansionChatParams,
  QueryExpansionChatResponse
>(
  "POST",
  "/api/query-expansion-chat",
  import.meta.env.VITE_COPILOT_API_URL,
  false
);

export const generateQueryExpansionsReportLLMService = apiFn<
  QueryExpansionReportParams,
  QueryExpansionReportResponse
>(
  "POST",
  "/api/query-expansion-report",
  import.meta.env.VITE_COPILOT_API_URL,
  false
);
