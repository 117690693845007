/**
 * An embeddable chat panel for CopilotKit.
 *
 * <img src="/images/CopilotChat/CopilotChat.gif" width="500" />
 *
 * A chatbot panel component for the CopilotKit framework. The component allows for a high degree
 * of customization through various props and custom CSS.
 *
 * <RequestExample>
 *   ```jsx CopilotChat Example
 *   import { CopilotChat } from "@copilotkit/react-ui";
 *
 *   <CopilotChat
 *     labels={{
 *       title: "Your Assistant",
 *       initial: "Hi! 👋 How can I assist you today?",
 *     }}
 *   />
 *   ```
 * </RequestExample>
 *
 * ## Custom CSS
 *
 * You can customize the colors of the panel by overriding the CSS variables
 * defined in the [default styles](https://github.com/CopilotKit/CopilotKit/blob/main/CopilotKit/packages/react-ui/src/css/colors.css).
 *
 * For example, to set the primary color to purple:
 *
 * ```jsx
 * <div style={{ "--copilot-kit-primary-color": "#7D5BA6" }}>
 *   <CopilotPopup />
 * </div>
 * ```
 *
 * To further customize the panel, you can override the CSS classes defined
 * [here](https://github.com/CopilotKit/CopilotKit/blob/main/CopilotKit/packages/react-ui/src/css/).
 *
 * For example:
 *
 * ```css
 * .copilotKitButton {
 *   border-radius: 0;
 * }
 * ```
 */

import {
  ChatContext,
  ChatContextProvider,
  CopilotChatIcons,
  CopilotChatLabels,
} from "./ChatContext";
import { Messages as DefaultMessages } from "./Messages";
import { Input as DefaultInput } from "./Input";
import { ResponseButton as DefaultResponseButton } from "./Response";
import { Suggestion } from "./Suggestion";
import React, { useEffect, useRef, useState } from "react";
import {
  SystemMessageFunction,
  useCopilotContext,
} from "@copilotkit/react-core";
import { useCopilotChat } from "./hooks/use-copilot-chat";
import { reloadSuggestions } from "./Suggestion";

import {
  Message,
  CopilotChatSuggestion,
  OnResponseCompleteCallback,
} from "../CustomMessages";
import { InputProps, MessagesProps, ResponseButtonProps } from "./props";

import { v4 as uuidv4 } from "uuid";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";

/**
 * Props for CopilotChat component.
 */
export interface CopilotChatProps {
  /**
   * Custom instructions to be added to the system message. Use this property to
   * provide additional context or guidance to the language model, influencing
   * its responses. These instructions can include specific directions,
   * preferences, or criteria that the model should consider when generating
   * its output, thereby tailoring the conversation more precisely to the
   * user's needs or the application's requirements.
   */
  instructions?: string;

  /**
   * A callback that gets called when the in progress state changes.
   */
  onInProgress?: (inProgress: boolean) => void;

  /**
   * A callback that gets called when a new message it submitted.
   */
  onSubmitMessage?: (message: Message) => void;

  /**
   * Icons can be used to set custom icons for the chat window.
   */
  icons?: CopilotChatIcons;

  /**
   * Labels can be used to set custom labels for the chat window.
   */
  labels?: CopilotChatLabels;

  /**
   * A function that takes in context string and instructions and returns
   * the system message to include in the chat request.
   * Use this to completely override the system message, when providing
   * instructions is not enough.
   */
  makeSystemMessage?: SystemMessageFunction;

  /**
   * Whether to show the response button.
   * @default true
   */
  showResponseButton?: boolean;

  /**
   * A custom Messages component to use instead of the default.
   */
  Messages?: React.ComponentType<MessagesProps>;

  /**
   * A custom Input component to use instead of the default.
   */
  Input?: React.ComponentType<InputProps>;

  /**
   * A custom ResponseButton component to use instead of the default.
   */
  ResponseButton?: React.ComponentType<ResponseButtonProps>;

  /**
   * A class name to apply to the root element.
   */
  className?: string;

  /**
   * Children to render.
   */
  children?: React.ReactNode;

  /**
   * The chatId to use for the chat.
   */
  chatId?: string;

  /**
   * A callback that gets called after the response from AI is received.
   */
  onResponseComplete?: OnResponseCompleteCallback;

  /**
   * Initial chat messages to display in the chat.
   */
  initialChatMessages?: Message[];

  /**
   * Whether to hide the filters accordion.
   * @default true
   */
  hideFilters?: boolean;
}

export function CopilotChat({
  instructions,
  onSubmitMessage,
  makeSystemMessage,
  showResponseButton = true,
  onInProgress,
  Messages = DefaultMessages,
  Input = DefaultInput,
  ResponseButton = DefaultResponseButton,
  className,
  icons,
  labels,
  chatId,
  onResponseComplete,
  initialChatMessages,
  hideFilters = true,
}: CopilotChatProps) {
  const context = useCopilotContext();

  useEffect(() => {
    context.setChatInstructions(instructions || "");
  }, [instructions]);

  const {
    visibleMessages,
    isLoading,
    currentSuggestions,
    sendMessage,
    stopGeneration,
    reloadMessages,
  } = useCopilotChatLogic(
    makeSystemMessage,
    onInProgress,
    onSubmitMessage,
    chatId,
    onResponseComplete,
    initialChatMessages
  );

  const chatContext = React.useContext(ChatContext);
  const isVisible = chatContext ? chatContext.open : true;

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({
    currentDocuments: true,
    myDocuments: true,
    organizationDocuments: true,
    qumisDatabase: false, // Always disabled
  });

  return (
    <WrappedCopilotChat icons={icons} labels={labels} className={className}>
      <Messages messages={visibleMessages} inProgress={isLoading}>
        {currentSuggestions.length > 0 && (
          <div>
            <h6>Suggested:</h6>
            <div className="suggestions">
              {currentSuggestions.map((suggestion, index) => (
                <Suggestion
                  key={index}
                  title={suggestion.title}
                  message={suggestion.message}
                  partial={suggestion.partial}
                  className={suggestion.className}
                  onClick={(message) => sendMessage(message)}
                />
              ))}
            </div>
          </div>
        )}
        {showResponseButton && visibleMessages.length > 0 && (
          <ResponseButton
            onClick={isLoading ? stopGeneration : reloadMessages}
            inProgress={isLoading}
          />
        )}
      </Messages>

      {!hideFilters && (
        <div
          className="border-t border-gray-200 dark:border-gray-700"
          onMouseEnter={() => setIsFiltersOpen(true)}
          onMouseLeave={() => setIsFiltersOpen(false)}
        >
          <button className="flex w-full items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800">
            Filters
            <svg
              className={`h-5 w-5 transition-transform duration-300 ease-in-out ${
                isFiltersOpen ? "rotate-180 transform" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              isFiltersOpen ? "max-h-96" : "max-h-0"
            }`}
          >
            <div className="px-4 py-2">
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(filters).map(([key, value]) => (
                  <div key={key} className="flex items-center justify-between">
                    <Label
                      htmlFor={key}
                      className="cursor-pointer text-sm font-medium text-black dark:text-white"
                    >
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                      {key === "qumisDatabase" && (
                        <span className="ml-1 text-xs text-gray-500">
                          <span className="rounded bg-gray-200 px-1 py-0.5 dark:bg-gray-700">
                            Coming Soon
                          </span>
                        </span>
                      )}
                    </Label>
                    <Switch
                      id={key}
                      checked={value}
                      onCheckedChange={(checked) =>
                        setFilters((prev) => ({
                          ...prev,
                          [key]: key === "qumisDatabase" ? false : checked,
                        }))
                      }
                      disabled={key === "qumisDatabase"}
                      className="data-[state=checked]:bg-black data-[state=unchecked]:bg-gray-200 dark:data-[state=unchecked]:bg-gray-700"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <Input
        inProgress={isLoading}
        onSend={sendMessage}
        isVisible={isVisible}
      />
    </WrappedCopilotChat>
  );
}

export function WrappedCopilotChat({
  children,
  icons,
  labels,
  className,
}: {
  children: React.ReactNode;
  icons?: CopilotChatIcons;
  labels?: CopilotChatLabels;
  className?: string;
}) {
  const chatContext = React.useContext(ChatContext);
  if (!chatContext) {
    return (
      <ChatContextProvider
        icons={icons}
        labels={labels}
        open={true}
        setOpen={() => {
          console.log("setOpen");
        }}
      >
        <div className={`copilotKitChat ${className}`}>{children}</div>
      </ChatContextProvider>
    );
  }
  return <>{children}</>;
}

const SUGGESTIONS_DEBOUNCE_TIMEOUT = 1000;

export const useCopilotChatLogic = (
  makeSystemMessage?: SystemMessageFunction,
  onInProgress?: (isLoading: boolean) => void,
  onSubmitMessage?: (message: Message, chatId: string) => void,
  chatId?: string,
  onResponseComplete?: OnResponseCompleteCallback,
  initialChatMessages?: Message[]
) => {
  const {
    visibleMessages,
    appendMessage,
    reloadMessages,
    stopGeneration,
    isLoading,
    setMessages,
  } = useCopilotChat({
    id: uuidv4(),
    makeSystemMessage,
    chatId,
    onResponseComplete,
  });

  useEffect(() => {
    if (initialChatMessages) {
      setMessages(initialChatMessages);
    }
  }, []); // Empty dependency array ensures this runs only on initial render

  const [currentSuggestions, setCurrentSuggestions] = useState<
    CopilotChatSuggestion[]
  >([]);
  const suggestionsAbortControllerRef = useRef<AbortController | null>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const abortSuggestions = () => {
    suggestionsAbortControllerRef.current?.abort();
    suggestionsAbortControllerRef.current = null;
  };

  const context = useCopilotContext();

  useEffect(() => {
    onInProgress?.(isLoading);

    abortSuggestions();

    debounceTimerRef.current = setTimeout(
      () => {
        if (
          !isLoading &&
          Object.keys(context.chatSuggestionConfiguration).length !== 0
        ) {
          suggestionsAbortControllerRef.current = new AbortController();
          reloadSuggestions(
            context,
            context.chatSuggestionConfiguration,
            setCurrentSuggestions,
            suggestionsAbortControllerRef
          );
        }
      },
      currentSuggestions.length == 0 ? 0 : SUGGESTIONS_DEBOUNCE_TIMEOUT
    );

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [isLoading, context.chatSuggestionConfiguration]);

  const sendMessage = async (messageContent: string) => {
    abortSuggestions();
    setCurrentSuggestions([]);

    const messageId = uuidv4();
    console.log(
      "messageIdmessageIdmessageIdmessageIdmessageIdmessageIdmessageId",
      messageId
    );

    const message: Message = {
      id: messageId,
      content: messageContent,
      role: "user",
      isVisible: true,
    };

    onSubmitMessage?.(message, chatId ?? "");
    appendMessage(message);
    return message;
  };

  return {
    visibleMessages,
    isLoading,
    currentSuggestions,
    sendMessage,
    stopGeneration,
    reloadMessages,
  };
};
