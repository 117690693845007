import tw from "twin.macro";
import styled from "styled-components";

const headingVariants = {
  h1: tw`text-xl`,
  h2: tw`text-base`,
};

interface ContainerProps {
  variant?: keyof typeof headingVariants;
}

const Heading = styled.h1<ContainerProps>(() => [
  tw`font-semibold`,
  ({ variant = "h1" }) => headingVariants[variant],
]);

export { Heading, headingVariants };
