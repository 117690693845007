import { QueryClient } from "react-query";
import {
  Form,
  redirect,
  useActionData,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { getInvite, createUser } from "~/api";
import { Destructive } from "~/components/Alert";
import { AuthBoxCenterText, AuthInput } from "~/components/AuthBox";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import { Label } from "~/components/ui/label";

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    try {
      await createUser({
        user: {
          password: data.password as string,
          inviteId: data.inviteId as string,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        return {
          status: 400,
          error: e.message,
        };
      }
    }

    await queryClient.invalidateQueries({ queryKey: ["session"] });

    return redirect("/");
  };

export const loader = async ({ params }: { params: { id?: string } }) => {
  return await getInvite({ id: params.id || "" });
};

const AcceptInvite = () => {
  const { invite } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigation = useNavigation();

  const actionData = useActionData() as
    | undefined
    | Awaited<{
        status: number;
        error: string;
      }>;

  const error = actionData?.error;
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      <AuthBoxCenterText>
        You have been invited to join the {invite.organization.name}{" "}
        organization
      </AuthBoxCenterText>
      {error && <Destructive>{error}</Destructive>}
      <Form method="post">
        <input type="hidden" name="inviteId" value={invite.id} />
        <div className="grid gap-2">
          <div>
            <Label htmlFor="email" className="sr-only">
              Email
            </Label>
            <AuthInput
              id="email"
              name="email"
              type="email"
              disabled={true}
              value={invite.email}
            />
          </div>
          <div className="grid gap-1">
            <Label htmlFor="password" className="sr-only">
              Password
            </Label>
            <AuthInput
              id="password"
              name="password"
              type="password"
              disabled={isSubmitting}
            />
            <div className="mb-4 text-sm text-muted-foreground">
              Create a password above. Must include at least 8 characters, 1
              number, 1 uppercase letter, 1 lowercase
            </div>
          </div>
          <ButtonWithLoader loading={isSubmitting}>
            Create Account
          </ButtonWithLoader>
        </div>
      </Form>
    </>
  );
};

export default AcceptInvite;
