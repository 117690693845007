import { Form, useActionData, useNavigation } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "~/components/ui/card";
import { Label } from "~/components/ui/label";
import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { createInvite } from "~/api";
import useSession from "~/hooks/useSession";

type ActionData = {
  success: boolean;
  invite?: Record<string, unknown>;
  error?: string;
};

export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const email = formData.get("email") as string;
  const organization_id = formData.get("organization_id") as string;

  try {
    const invite = await createInvite({ email, organization_id });
    return { success: true, invite };
  } catch (error) {
    return { success: false, error: "Failed to create invites" };
  }
};

export default function AdminQumisInviteUsers() {
  const actionData = useActionData() as ActionData | undefined;
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const { session } = useSession();

  return (
    <Card className="mx-auto max-w-md">
      <Form method="post">
        <CardHeader>
          <CardTitle>Invite Users</CardTitle>
          <CardDescription>
            Please Enter the email address and organizational ID to invite a
            user.
          </CardDescription>
        </CardHeader>

        <CardContent className="space-y-4">
          <div className="grid gap-2">
            <Label htmlFor="organizational-id">Organizational ID</Label>
            <Input
              id="organizational-id"
              name="organization_id"
              type="text"
              placeholder="Enter organizational ID"
              required
              defaultValue={session?.organizationId || ""}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="user-email">User Email</Label>
            <Input
              id="user-email"
              name="email"
              type="email"
              placeholder="Enter user's email"
              required
            />
          </div>
        </CardContent>
        <CardFooter className="flex justify-end">
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Inviting..." : "Invite User"}
          </Button>
        </CardFooter>
      </Form>
      {actionData && (
        <p
          className={`mt-4 text-center ${
            actionData.success ? "text-green-600" : "text-red-600"
          }`}
        >
          {actionData.success ? "Invite sent successfully!" : actionData.error}
        </p>
      )}
    </Card>
  );
}
