import { Form } from "react-router-dom";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import ColorRadioInput from "~/components/ui/color-radio-input";
import ButtonWithLoader from "~/components/ButtonWithLoader";
import "twin.macro";

interface LogInFormProps {
  disableSubmit: boolean;
  submitting: boolean;
  setFile: (file: File | null) => void;
  buttonName: string;
  setLoading?: (loading: boolean) => void; // Add this line
}

const UploadForm = ({
  disableSubmit,
  submitting,
  setFile,
  buttonName,
  setLoading,
}: LogInFormProps) => (
  <Form
    method="post"
    encType="multipart/form-data"
    className="flex flex-col gap-5"
    onSubmit={() => {
      if (setLoading) {
        setLoading(true);
      }
    }}
  >
    <div>
      <Input
        name="file"
        id="file-field"
        type="file"
        onChange={(e) => setFile(e.target.files?.[0] || null)}
        accept="application/pdf"
      />

      <p className="mt-1 text-xs text-gray-400">PDF format only</p>
    </div>
    <fieldset>
      <Label htmlFor="claimNumber">Create Matter Number:</Label>
      <Input
        className="mt-2"
        type="text"
        id="claimNumber"
        name="claimNumber"
        placeholder="Example: 0001_CLAIM"
      />
    </fieldset>
    <fieldset>
      <Label asChild className="mb-2">
        <legend>Matter Tag Color:</legend>
      </Label>
      <div>
        <ColorRadioInput name="claimColor" label="purple" value="#4e2a84" />
        <ColorRadioInput name="claimColor" label="blue" value="#2660ff" />
        <ColorRadioInput name="claimColor" label="red" value="#e31b22" />
        <ColorRadioInput name="claimColor" label="green" value="#00c769" />
      </div>
    </fieldset>
    <div>
      <ButtonWithLoader
        disabled={disableSubmit}
        loading={submitting}
        tw="w-full text-white bg-black hover:bg-primary/90"
      >
        {buttonName}
      </ButtonWithLoader>
    </div>
  </Form>
);

export default UploadForm;
