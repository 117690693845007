import { apiFn } from "~/api/utils";

export interface SubscriptionResponseData {
  stripeCustomerId: string;
  subscriptionStatus: string;
  billingStatus: string;
}

export const getSubscription = apiFn<
  void,
  { subscription: SubscriptionResponseData }
>("GET", `/subscription`);
